import { Component, Input, OnInit } from '@angular/core';
import { Warehouse } from 'src/app/interfaces/assets/warehouse.interface';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-warehouse-outline',
    templateUrl: './warehouse-outline.component.html',
    styles: [],
    standalone: true,
    imports: [MatIcon, NgIf]
})
export class WarehouseOutlineComponent implements OnInit {
  @Input() warehouse: Warehouse;

  constructor() {}

  ngOnInit(): void {}
}
