import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MemoryService } from 'src/app/services/memory.service';
import { DialogSupportComponent } from '../dialogs/dialog-support/dialog-support.component';
import { config } from 'src/config';
import { InspectorService } from 'src/app/views/inspector/tasks/inspector.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { Router, RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { BackendService } from 'src/app/services/backend.service';
import { JwtService } from 'src/app/services/jwt.service';
import { DecodedJwt } from 'src/app/services/token.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Capability } from 'src/app/interfaces/user/capability.interface';
import { CapabilityCheckPipe } from 'src/app/pipes/check-capability.pipe';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    RouterModule,
    UserAvatarComponent,
    CapabilityCheckPipe
]
})
export class HeaderComponent implements OnInit {
  @Input() isMobile = false;
  @Input() navbarOpen = true;
  @Output() navbarToggled = new EventEmitter<boolean>();

  languages: { label: string; value: string }[];
  availableLanguages: string[];
  roleId: number;

  openTasks: number;
  isLoggedIn = false;

  Capability = Capability;

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private memory: MemoryService,
    private readonly router: Router,
    public readonly jwtService: JwtService,
    private readonly inspectorService: InspectorService,
    private readonly translocoService: TranslocoService,
    private readonly backendService: BackendService
  ) {
    this.cd.detach();
    this.availableLanguages = this.translocoService.getAvailableLangs() as string[];
    this.languages = [];
    for (const lang of this.availableLanguages) {
      this.languages.push({ label: config.languages[lang], value: lang });
    }
  }

  ngOnInit(): void {
    window.setTimeout(() => {
      this.cd.detectChanges();
      this.cd.reattach();
    }, 500);

    this.jwtService.$loginChange.subscribe(({ userdata }) => this.onLoginChanged(userdata));
    if (this.jwtService.isLoggedIn) this.onLoginChanged(this.jwtService.userData);
  }

  onLoginChanged(userData: DecodedJwt) {
    this.isLoggedIn = userData !== null;
    if (!this.isLoggedIn) return;
      this.roleId = userData.role.id;
      if (this.roleId === 6) {
        this.backendService.post<'user/getUserNotifications'>('user/notifications', {
          body: {
            subscriptionTypes: ['follow-up']
          }
        }).subscribe((data) => {
          this.openTasks = data.length;
        });
        this.inspectorService.openTasks$.subscribe((tasks) => {
          this.openTasks = tasks;
        });
      } else if (this.roleId === 10) {
        this.backendService.post<'user/getUserNotifications'>('user/notifications', {
          body: {
            subscriptionTypes: ['missing', 'defect', 'notFitting', 'price-raise', 'base-item']
          }
        }).subscribe((data) => {
            this.openTasks = data.length;
            this.inspectorService.openTasks$.subscribe((tasks) => {
              this.openTasks = tasks;
            });
          });
      } else {
        this.openTasks = null;
      }
  }

  reload() {
    window.location.reload();
  }

  onSupportClicked(): void {
    this.dialog.open(DialogSupportComponent, {
      width: '90%',
      maxWidth: '500px',
      data: {}
    });
  }

  mimicReset() {
    this.backendService.post<'admin/mimicReset'>('admin/mimic/reset').subscribe((data) => {
      this.jwtService.token = data.token;
      this.router.navigateByUrl('/user/list').then(() => window.location.reload());
    });
  }

  useLanguage(id: string): void {
    this.memory.changeLanguage(id);
  }
}
