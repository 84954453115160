import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { MemoryService } from './services/memory.service';
import { InspectorService } from './views/inspector/tasks/inspector.service';
import { MobileViewService } from './services/mobile-view.service';
import { LoginComponent } from './views/authentication/login/login.component';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { HeaderComponent } from './components/header/header.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { JwtService } from './services/jwt.service';
import { ApiResponses, BackendService } from './services/backend.service';
import { NotificationService } from './services/notification.service';

type StaticPage = ApiResponses['static/getAllPages'][0];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    HeaderComponent,
    MatSidenavContainer,
    MatSidenav,
    NavigationComponent,
    MatSidenavContent,
    MatProgressBar,
    RouterOutlet,
    MatAnchor,
    MatIcon
  ]
})
export class AppComponent implements OnInit {
  @ViewChild('sideNav') sideNav!: MatSidenav;

  showDrawer = true;
  title = environment.appTitle;
  navigating = false;

  awaitingLogin = false;

  staticPageMenu: { [s: string]: StaticPage[] } = {};

  showReload: boolean;
  reloadTime = 5;

  isMobileView: boolean;
  isOpened: boolean;
  isLoggedIn: boolean;
  intervalId: NodeJS.Timeout;
  showHeader = true;

  constructor(
    private readonly jwtService: JwtService,
    private readonly backendService: BackendService,
    private memoryService: MemoryService,
    private router: Router,
    private readonly notificationService: NotificationService,
    private readonly inspectorService: InspectorService,
    private readonly mobileViewService: MobileViewService
  ) {
    this.isOpened = false;
    this.isMobileView = mobileViewService.detectMobileDevice();

    if (this.jwtService.isLoggedIn) {
      this.isLoggedIn = true;
      this.onLoggedIn();
    }
    this.jwtService.$loginChange.subscribe((result) => {
      if (!result.userdata) {
        this.isLoggedIn = false;
        return;
      }
      this.isLoggedIn = true;
      this.onLoggedIn();
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.navigating = true;
      }
      if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        this.navigating = false;
      }
    });

    this.memoryService.$changeSideMenuVisibility.subscribe((e) => {
      this.isOpened = false;
      this.isMobileView = e;
    });

    this.memoryService.$changeHeaderVisibility.subscribe((e) => {
      this.showHeader = e;
    });
  }

  ngOnInit(): void {
    this.isMobileView = this.mobileViewService.detectMobileDevice();

    this.notificationService.showReloadButton$.subscribe((reload) => {
      this.showReload = reload;
      this.intervalId = setInterval(() => {
        if (this.reloadTime > 0) {
          this.reloadTime--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
      setTimeout(() => {
        this.reload();
      }, 5000);
    });
    document.title = this.title;
  }

  onLoggedIn() {
    this.awaitingLogin = false;
    if (this.jwtService.role?.id === 13) {
      this.showDrawer = false;
    } else {
      this.showDrawer = true;
      this.backendService.get<'static/getAllPages'>('static/page/all').subscribe((p) => {
        this.buildMenuFromStaticPages(p);
      });
    }
  }

  toggleDrawer() {
    this.isOpened = !this.isOpened;
  }

  onMenuItemSelected() {
    if (this.isMobileView) this.isOpened = false;
  }

  onActivate(event) {
    if (!(event instanceof LoginComponent)) {
      this.jwtService.$loginChange.subscribe((result) => {
        if (!result.userdata) return;

        const loginData = result.userdata;
        if (loginData?.role?.id === 6) {
          this.backendService.post<'user/getAllNotifications'>('user/notifications/all').subscribe((data) => {
            this.inspectorService.openTasks$.next(data.length);
          });
        } else if (loginData.role.id === 10) {
          this.backendService
            .post<'user/getUserNotifications'>('user/notifications', {
              body: {
                subscriptionTypes: ['missing', 'defect', 'notFitting', 'price-raise', 'base-item']
              }
            })
            .subscribe((data) => {
              this.inspectorService.openTasks$.next(data.length);
            });
        }
      });
    }
  }

  reload() {
    this.showReload = false;
    window.location.reload();
  }

  navBarToggle(): void {
    this.isOpened = !this.isOpened;
  }

  private buildMenuFromStaticPages(staticPages: StaticPage[]) {
    const menu: { [s: string]: StaticPage[] } = {};
    for (const page of staticPages) {
      menu[page.menu] = menu[page.menu] || [];
      menu[page.menu].push(page);
    }
    this.staticPageMenu = menu;
  }
}
