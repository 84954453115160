export const environment = {
  production: false,
//   apiUrl: 'https://stagingapi.deymann.app/',
  wsApiUrl: 'stagingapi.deymann.app',
  apiUrl: 'http://localhost:3000/',
//   wsApiUrl: 'localhost:3000',
  frontentUrl: 'https://staging.deymann.app/',
  languages: { de: 'Deutsch', en: 'English', nl: 'Nederlands', pl: 'Polský', cs: 'Česky' },
  environment: 'staging-deymann',
  developerEmails: ['info@complink.app', 'hans.voelzer@com-and.de', 'william@com-and.de'],
  googleMapAPI: 'AIzaSyDOYySSG6fWNoPAfupTZKaHSzw5HBJMbp0',
  appTitle: 'DEYMANN.APP',
  appVersion: require('../../package.json').version + '-stg'
};
