import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { BackendService, BackendObservable } from './backend.service';
import { ICreateUser } from '../interfaces/user/create-user.interface';
import { Role } from '../interfaces/user/role.interface';
import { UserGroup } from '../interfaces/user/user-group.interface';
import { UserNotification } from '../interfaces/user/user-notification.interface';
import { UserData, User, UserLogin } from '../interfaces/user/user.interface';
import { Capability } from '../interfaces/user/capability.interface';
import { SiteUserModel } from '../models/site-user.model';
import { MenuItem } from '../interfaces/user/menu-item.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BackendService {
  getRoleViewSetting(key: string): Observable<MenuItem> {
    return this.get<MenuItem>(`role/${this.memoryService.getLoginData().roleId}/setting/${key}/get`);
  }

  login(email: string, password: string): Observable<UserData> {
    return this.post<UserData>('authenticate', { email, password }).pipe((d) => {
      BackendService.LOGIN_CHECK_PENDING = false;
      return d;
    });
  }

  requestResetPassword(email: string): Observable<any> {
    return this.post<any>('password/requestReset', { email });
  }

  resetPassword(email: string, passwordResetToken: string, password: string): Observable<any> {
    return this.post<any>('password/reset', { email, passwordResetToken, password });
  }

  editPassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.post<any>('password/edit', { old_password: oldPassword, new_password: newPassword });
  }

  createTicket(subject: string, body: string, ticketType: string, email?: string): Observable<any> {
    return this.post<any>('createTicket', {
      subject,
      body,
      ticketType,
      userAgent: navigator.userAgent,
      currentUri: window.location.href,
      user: this.memoryService.getLoginData(),
      email
    });
  }

  updateSiteUser(siteId: number): Observable<SiteUserModel> {
    return this.post<SiteUserModel>('updateSiteUser', { siteId });
  }

  getAllUsers(
    criteria: any,
    limit?: number,
    offset?: number,
    order?: string[][]
  ): BackendObservable<ISequelizeCount<User>> {
    return this.post<ISequelizeCount<User>>('all/get', { criteria, limit, offset, order })
      .pipe(
        map((data) => {
          for (const user of data.rows) {
            user.userLogin.userGroups = user.userLogin.usersGroups.map((group) => group.userGroup);
          }
          return data;
        })
      )
      .pipe(this.convertToModel(User)) as BackendObservable;
  }

  getAllUsersCached(): Observable<ISequelizeCount<User>> {
    return this.cachingService.createCachingSubscription(
      'getAllUsersCached',
      this.post<ISequelizeCount<User>>('all/get', {})
    );
  }

  getAllUserLogins(criteria: any, userGroupCriteria?: any): Observable<UserLogin[]> {
    return this.post<UserLogin[]>('userLogins/get', { criteria, userGroupCriteria });
  }

  createUser(initialUserData: ICreateUser): Observable<UserLogin> {
    return this.post<UserLogin>(`create`, initialUserData).pipe(this.convertToModel(UserLogin));
  }

  createUserWithPassord(userData: ICreateUser): Observable<UserLogin> {
    return this.post<UserLogin>(`createWithPassword`, userData).pipe(this.convertToModel(UserLogin));
  }

  editUser(user: User, groups?: string[]): Observable<User> {
    return this.post<User>(`${user.userId}/edit`, { userData: user, groups }).pipe(this.convertToModel(User));
  }

  toggleActive(userId: number, active: boolean): Observable<UserLogin> {
    return this.post<UserLogin>(`${userId}/toggleActive`, { active }).pipe(this.convertToModel(UserLogin));
  }

  editUserBatch(userIds: number[], attributes: any, groups?: string[]): Observable<any> {
    return this.post<any>(`editBatch`, {
      userIds,
      attributes,
      groups
    });
  }

  /**
   * Get all roles except for the SuperAdmin role. This role must be choosen carefully, all Superadmins can access the staging app.
   *
   * @returns
   */
  getAllRoles(): Observable<ISequelizeCount<Role>> {
    return this.cachingService.createCachingSubscription(
      'getAllRoles',
      this.post<ISequelizeCount<Role>>('role/all/get', { criteria: { roleId: { $not: 8 } } })
    );
  }

  getAllUserGroups(): Observable<ISequelizeCount<UserGroup>> {
    return this.cachingService.createCachingSubscription(
      'getAllUserGroups',
      this.post<ISequelizeCount<UserGroup>>('group/all/get', {})
    );
  }

  createGroup(name: string, parentId?: string): Observable<UserGroup> {
    return this.post<UserGroup>('group/create', { name, parentId }).pipe((group) => {
      this.cachingService.invalidateCache('getAllUserGroups');
      return group;
    });
  }

  editGroup(userGroupId: string, groupData: UserGroup): Observable<UserGroup> {
    return this.post<UserGroup>(`group/${userGroupId}/edit`, { data: groupData }).pipe((group) => {
      this.cachingService.invalidateCache('getAllUserGroups');
      return group;
    });
  }

  getParentUserGroup(userGroupId: string): Observable<UserGroup> {
    return this.get<UserGroup>(`group/${encodeURIComponent(userGroupId.replace('/', ','))}/getParent`);
  }

  getDisabledUserColumns(roleId: number): Observable<any> {
    return this.get<any>(`role/${roleId}/getDisabledUserColumns`);
  }

  getAllCapabilities(
    criteria?: any,
    limit?: number,
    offset?: number
  ): Observable<{ capabilities: Capability[]; descriptions: any }> {
    return this.post<{ capabilities: Capability[]; descriptions: any }>('role/all/getCapabilities', {
      criteria,
      limit,
      offset
    });
  }

  createRole(name: string): Observable<Role> {
    return this.post<Role>('role/create', { name }).pipe((role) => {
      this.cachingService.invalidateCache('getAllRoles');
      return role;
    });
  }

  editCapabilities(roleId: number, capabilities: string[]): Observable<Role> {
    return this.post<Role>(`role/${roleId}/editCapabilities`, { capabilities }).pipe((role) => {
      this.cachingService.invalidateCache('getAllRoles');
      this.cachingService.invalidateCache(`getOwnCapabilities_r:${roleId}`);
      return role;
    });
  }

  downloadAllUsers(criteria: any, additionalColumns: string[] = []): void {
    this.performDownload('user/all/export', {
      criteria: JSON.stringify(criteria),
      additionalColumns: JSON.stringify(additionalColumns)
    });
  }

  downloadDocumentStatus(criteria: any, selectedSubtypes: number[], mode?: string): void {
    this.performDownload('user/all/exportDocumentValidationStatus', {
      mode,
      subtypeIds: selectedSubtypes.join(','),
      criteria: JSON.stringify(criteria)
    });
  }

  subscribeToPush(subscription: PushSubscription): Observable<UserNotification> {
    return this.post<UserNotification>('push/subscribe', { subscription });
  }

  unsubscribePush(subscription: PushSubscription) {
    return this.post<any>('push/unsubscribe', { subscription });
  }

  editSubscription(subscription: UserNotification) {
    return this.post<any>('push/edit', { subscription });
  }

  deleteSubscription(userNotificationId: any): Observable<any> {
    return this.post<any>(`push/${userNotificationId}/delete`, {});
  }

  getPushSubscriptions(userId?: number): Observable<UserNotification[]> {
    if (!userId) {
      userId = this.memoryService.getLoginData().userId;
    }
    return this.get<UserNotification[]>(`${userId}/push/getAll`);
  }

  getCapabilitiesByRoleId(roleId?: number): Observable<Capability[]> {
    if (!roleId && this.memoryService.isLoggedIn()) {
      roleId = this.memoryService.getLoginData().roleId;
    }
    return this.cachingService.createCachingSubscription(
      `getOwnCapabilities_r:${roleId}`,
      this.post<Capability[]>(`role/${roleId}/getCapabilities`, {})
    );
  }

  //   getDocumentValidationStatus(
  //     criteria: any,
  //     subtypeIds: number[]
  //   ): Observable<{ userCount: number; chartData: ChartDataSets[]; chartLabels: string[] }> {
  //     return this.post<{ chartData: ChartDataSets[]; chartLabels: string[]; userCount: number }>(
  //       `all/getDocumentValidationStatus`,
  //       { criteria, subtypeIds }
  //     );
  //   }

  getUserAttributes(): Observable<{ [s: string]: string }> {
    return this.get<{ [s: string]: string }>(`getUserAttributes`);
  }

  getUserAttributeValues(criteria: any): Observable<User[]> {
    return this.post<User[]>(`getUserAttributeValues`, { criteria });
  }

  async checkPermission(permissions: string[], logicalOp: 'OR' | 'AND'): Promise<boolean> {
    let hasPermission = false;
    for (const checkPermission of permissions) {
      const permissionFound = await this.hasCapability(checkPermission).toPromise();
      if (permissionFound) {
        hasPermission = true;
        if (logicalOp === 'OR') {
          break;
        }
      } else {
        hasPermission = false;
        if (logicalOp === 'AND') {
          break;
        }
      }
    }
    return hasPermission;
  }

  hasCapability(capabilityName: string, roleId?: number): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.getCapabilitiesByRoleId(roleId).subscribe((caps) => {
        observer.next(!!caps.find((c) => c.name === capabilityName));
        observer.complete();
      });
    });
  }

  getUserNotifications(onlyOwn = true, criteria?: any): Observable<ISequelizeCount<UserNotification>> {
    return this.post<ISequelizeCount<UserNotification>>(`getUserNotifications`, { criteria, onlyOwn });
  }

  deleteUserNotification(criteria: any): Observable<number> {
    return this.post<number>(`deleteUserNotification`, { criteria });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`user/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`user/${url}`, options);
  }
}
