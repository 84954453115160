import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { GetSitePipe } from '../../pipes/get-site.pipe';
import { ApiResponses, BackendService } from 'src/app/services/backend.service';
import { config } from 'src/config';

type User = ApiResponses['user/getUser'];

@Component({
  selector: 'app-user-outline',
  templateUrl: './user-outline.component.html',
  styleUrls: ['./user-outline.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, GetSitePipe, MatTooltipModule, MatChipsModule, RouterModule]
})
export class UserOutlineComponent implements OnChanges {
  @Input() size: 'small' | 'normal' | 'large' = 'small';
  @Input() user: User;
  @Input() userId?: number;
  userGroupParentIcon: string;

  backendUrl = config.apiUrl;

  constructor(private readonly backendService: BackendService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userId) {
      this.backendService
        .get<'user/getUser'>('user/{id}', {
          path: { id: this.userId }
        })
        .subscribe((u) => {
          this.user = u as any; // TODO: type
        });
    }
  }

  stringify(obj: any): string {
    return JSON.stringify(obj);
  }
}
