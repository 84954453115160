import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { BackendService, subscriptionIsActive } from 'src/app/services/backend.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { InspectorService } from 'src/app/views/inspector/tasks/inspector.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonModule } from '@angular/common';
import { MemoryService } from 'src/app/services/memory.service';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InstallDialogComponent } from './not-pwa-dialog/install-dialog.component';
import { TestAppDialogComponent } from './not-live-app-dialog/test-app-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { FormDataError } from 'src/app/interfaces/errors/form-data.error';
import { UserLogin } from 'src/app/interfaces/user/user.interface';
import { ShoppingService } from '../../user/crew-member/shopping-cart/shopping.service';
import { RouteService } from 'src/app/route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule
  ]
})
export class LoginComponent implements OnInit {
  subscriptionIsActive = subscriptionIsActive;

  title = environment.appTitle;

  version = localStorage.getItem('app_version') || '1.0.0';
  error: string;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  loginSubscription: Subscription;

  constructor(
    private userService: UserService,
    private memoryService: MemoryService,
    private router: Router,
    private readonly mobileService: MobileViewService,
    private backend: BackendService,
    private readonly dialog: MatDialog,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly inspectorService: InspectorService,
    private readonly notify: NotificationService,
    private readonly routeService: RouteService,
    private readonly shoppingService: ShoppingService
  ) {}

  ngOnInit(): void {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    if (environment.environment.includes('staging')) {
      this.dialog.open(TestAppDialogComponent, { ...dimensions });
    } else {
      if (!this.mobileService.detectIfPWA() && this.mobileService.detectMobileDevice()) {
        if (!localStorage.getItem('first_steps_show')?.includes('true')) {
          this.dialog.open(InstallDialogComponent, { ...dimensions });
        }
      }
    }
    if (this.memoryService.isLoggedIn()) {
      const userLogin = this.memoryService.getLoginData();
      if (userLogin?.role?.entryUri) {
        this.router.navigateByUrl(this.memoryService.getLoginData().role.entryUri);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  submit(): void {
    if (this.form.valid) {
      this.loginSubscription = this.userService
        .login(this.form.controls.email.value, this.form.controls.password.value)
        .subscribe({
          next: (d) => {
            if (
              environment.environment.includes('staging') &&
              !environment.developerEmails.includes(d.userLogin.email)
            ) {
              // do not login on staging without a Developer Account role!
              this.notify.error(
                'This is the development app! Please use a developer account for login! Or visit the live app instead!',
                10
              );
            } else {
              this.backend.setResponseMeta({
                login_token: d.userToken.loginToken,
                expire: d.userToken.expires_at
              });

              const userData: UserLogin = d.userLogin;
              userData.loginToken = d.userToken.loginToken;
              userData.loginTokenExpiresAt = d.userToken.expires_at;

              this.memoryService.setLoggedIn(userData);
              this.shoppingService.clearCart().subscribe(() => this.shoppingService.setCartItemsAndIds([]));
              window.setTimeout(() => {
                this.onLoggedIn();
              }, 1000);
            }
          },
          error: (e) => {
            if (e instanceof FormDataError) {
              UserService.handleFormDataError(e, this.form);
            } else {
              this.error = e.error.error.message;
            }
          }
        });
    }
  }

  onLoggedIn(): void {
    if (this.memoryService.getLoginData()?.role?.entryUri) {
      const loginData = this.memoryService.getLoginData();
      this.router.navigateByUrl(loginData.role.entryUri);
    } else {
      const loginData = this.memoryService.getLoginData();
      if (![6, 10].includes(loginData?.roleId)) {
        this.inspectorService.openTasks$.next(0);
      }
      // if the user attempted to reach a specific route before login
      const attemptedRoute = this.routeService.getAttemptedRoute();
      if (attemptedRoute) {
        this.router.navigate([attemptedRoute]);
        this.routeService.clearAttemptedRoute();
      } else {
        if (loginData.role?.entryUri) {
          this.router.navigate([loginData.role.entryUri]);
        } else {
          this.router.navigate(['/user/dashboard']);
        }
      }
    }
  }
}
