import { isEmpty } from "biolib";

export class Criteria {
  private criteria: { [s: string]: any };

  constructor(data: { [s: string]: any }) {
    this.criteria = data;
  }

  static criteriaToLike(criteria: any, toBeLike: string[] | false = false, fullLike = true): Criteria {
    criteria = Object.assign({}, criteria);
    for (const attr in criteria) {
      if (criteria.hasOwnProperty(attr) && (toBeLike === false || toBeLike.includes(attr))) {
        if (typeof criteria[attr] === 'object') {
          continue; //ignore complex values
        }
        if (fullLike) {
          criteria[attr] = { $like: `%${criteria[attr]}%` };
        } else {
          criteria[attr] = { $like: `${criteria[attr]}%` };
        }
      }
    }
    return criteria;
  }

  toLike(attributes: string[] | false = false, fullLike = true): Criteria {
    this.criteria = Criteria.criteriaToLike(this.criteria, attributes, fullLike);
    return this;
  }

  clearEmptyStrings(): Criteria {
    for (const attr in this.criteria) {
      if (this.criteria.hasOwnProperty(attr) && (this.criteria[attr] === '' || this.criteria[attr] === undefined)) {
        delete this.criteria[attr];
      }
    }
    return this;
  }

  toJSON(): any {
    return this.criteria;
  }

  toQueryString() {
    return isEmpty(this.criteria) ? null : JSON.stringify(this.criteria);
  }
}
