import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader, TranslocoModule, provideTransloco } from '@ngneat/transloco';
import { inject, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'de', 'nl', 'cs', 'pl'],
        defaultLang: 'de',
        reRenderOnLangChange: true,
        prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    })
  ]
})
export class TranslocoRootModule {}
