<ng-container *transloco="let t">
  <mat-menu #appMenu="matMenu" xPosition="before">
    @if (mobile || userLogin?.roleId === 13) {
      <p class="space-sm-left space-sm-right menu-item flex align-center">
        <img src="/assets/logo/logo_default_small.png" height="20px" /> {{ version }}
      </p>
      <hr />
    }
    <button *ngIf="![2, 5, 12, 13].includes(userLogin?.roleId)" mat-menu-item routerLink="/user/me">
      <mat-icon class="material-symbols-outlined">account_box</mat-icon> {{ t('components.useravatar.profile') }}
    </button>
    <button mat-menu-item *ngIf="userLogin?.roleId === 6" routerLink="/inspector/tasks">
      <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">task</mat-icon>
      {{ t('components.useravatar.tasks') }}
    </button>
    <button mat-menu-item *ngIf="userLogin?.roleId === 10" routerLink="/equipment/user">
      <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">task</mat-icon>
      {{ t('components.useravatar.tasks') }}
    </button>
    <button mat-menu-item *ngIf="[1, 8].includes(userLogin?.roleId)" (click)="onToDoClicked()">
      <mat-icon>task</mat-icon>
      {{ t('components.useravatar.todo') }}
    </button>
    @if ([1, 8].includes(userLogin?.roleId)) {
      <button mat-menu-item routerLink="/user/settings">
        <mat-icon>settings</mat-icon> {{ t('components.useravatar.settings') }}
      </button>
    }
    <button mat-menu-item (click)="onLogoutClicked()"><mat-icon>exit_to_app</mat-icon> Logout</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="appMenu" *ngIf="userLogin">
    <span class="hidden-xs"> {{ t('components.useravatar.greeting') }}, {{ userLogin.user.forename }} </span>
    <mat-icon class="visible-xs">account_box</mat-icon>
    <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">more_vert</mat-icon>
  </button>
</ng-container>
