import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Settings } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class MemoryService {
  $changeSideMenuVisibility = new Subject<boolean>();
  $changeHeaderVisibility = new Subject<boolean>();

  constructor(private translocoService: TranslocoService) {}

  changeLanguage(id: string): void {
    const lang = id.includes('.') ? id.split('.')[0] : id;
    localStorage.setItem('language', id);
    this.translocoService.setActiveLang(id);
    Settings.defaultLocale = lang;
  }
}
