import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { FilterListMenuOutletComponent } from './filter-list-menu-outlet/filter-list-menu-outlet.component';
import { StaticPageModel } from 'src/app/interfaces/static-pages/static-page.model';
import { ObjectKeysPipe } from 'src/app/pipes/object-keys.pipe';
import { Capability } from 'src/app/interfaces/user/capability.interface';
import { CapabilityCheckPipe } from '../../pipes/check-capability.pipe';
import { JwtService } from 'src/app/services/jwt.service';
import { StartProcessService } from '../visits-reports/start/start-process.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  providers: [StartProcessService],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    ObjectKeysPipe,
    FilterListMenuOutletComponent,
    TranslocoModule,
    CapabilityCheckPipe
  ]
})
export class NavigationComponent implements OnInit {
  @Input() staticPageMenu: { [s: string]: StaticPageModel[] } = {};
  @Output() itemSelected = new EventEmitter();
  //   @Output() handleStaticPageAction = new EventEmitter<StaticPageModel>();

  currentDate = new Date();
  title = environment.appTitle;
  production = environment.production;
  version = localStorage.getItem('app_version');
  roleId: number;
  capabilities: Capability[];
  Capability = Capability;

  constructor(
    private readonly jwtService: JwtService,
    private readonly startProcessService: StartProcessService,
    private readonly backendService: BackendService
  ) {}

  ngOnInit() {
    this.roleId = this.jwtService.role?.id;
    this.capabilities = this.jwtService.role?.capabilities;
  }

  handleStaticPageAction(staticPage: any): void {
    switch (staticPage.action?.action) {
      case 'startProcessByDefinitionId':
        this.backendService
          .post<'processEngineProcess/getAllActiveSteps'>('process-engine/process/getAllActiveSteps', {
            body: {
              onlyInputSteps: true,
              includeProcessDataFields: true,
              criteria: {
                '$process.process_definition_id$': 16,
                executed: null
              }
            } as any // TODO: type
          })
          .subscribe((steps) => {
            this.startProcessService.startProcess(
              16,
              this.jwtService.userData.userId,
              null,
              'emergency',
              steps.result as any, // TODO: type
              this.startProcessService.generatePredefinedDatafieldsByDefinitionId(16),
              true
            );
          });
        break;
    }
  }
}
