import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RunningComponent } from '../../visits-reports/running/running.component';
import { MatButtonModule } from '@angular/material/button';
import { ProcessService } from 'src/app/services/process.service';
import { ExpansionTableData } from '../../expansion-table/interfaces/expansion-table-data.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { AllStepsPerformedEvent } from 'src/app/process-engine/process/process-handler-dialog/process-handler-dialog.component';
import { DataFieldTranslation } from 'src/app/interfaces/process/data-field.interface';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-already-running',
  templateUrl: './already-running.component.html',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslocoModule, RunningComponent]
})
export class AlreadyRunningDialogComponent {
  expansionTableData: ExpansionTableData[];
  constructor(
    private readonly processService: ProcessService,
    private readonly notify: NotificationService,
    private readonly transloco: TranslocoService,
    public dialogRef: MatDialogRef<AlreadyRunningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { alreadyRunningSteps: Step[]; translations: DataFieldTranslation[]; processPhase?: string }
  ) {
    let phase = data.processPhase;
    if (!data.processPhase) {
      phase = 'visits';
    }
    processService
      .convertToExpansionTableData(data.alreadyRunningSteps, phase, data.translations)
      .then((res) => (this.expansionTableData = res));
  }

  processClosed(event) {
    if (event instanceof AllStepsPerformedEvent) {
      this.dialogRef.close(event);
    }
    if (event?.actionPerformed) {
      this.dialogRef.close(event);
    }
  }

  onDelete(event: { processId: number; title: string; vessel: string; date: Date }) {
    this.processService.deleteProcess(event.processId).subscribe(() => {
      let index = this.expansionTableData.findIndex((row) => row.processId === event.processId);
      if (index > -1) {
        const expansionTableDataNew = this.expansionTableData;
        this.expansionTableData = null;
        expansionTableDataNew.splice(index, 1);
        this.expansionTableData = [...expansionTableDataNew];

        this.notify.success(this.transloco.translate('process.deleted'));
      } else {
        this.notify.error(`An Error occured when rtrying to delete process: ${event.processId}`);
      }

      index = this.data.alreadyRunningSteps.findIndex((step) => step.process_id === event.processId);
      if (index > -1) {
        const oldRunning = this.data.alreadyRunningSteps;
        this.data.alreadyRunningSteps = null;
        oldRunning.splice(index, 1);
        this.data.alreadyRunningSteps = [...oldRunning];
      } else {
        this.notify.error(`An Error occured when rtrying to delete process: ${event.processId}`);
      }

      if (!this.data.alreadyRunningSteps.length) this.dialogRef.close();
    });
  }
}
