import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AssetConditionIndicatorComponent } from '../../asset/asset-condition-indicator/asset-condition-indicator.component';
import { MatIconModule } from '@angular/material/icon';

export interface AddressData {
  confirmation: boolean;
  addressComponents: any[];
  initialAddress: string;
}

@Component({
  selector: 'app-confirm-google-address',
  templateUrl: './confirm-address-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatRadioModule,
    MatCardModule,
    TranslocoModule
  ]
})
export class ConfirmAddressComponent {
  hideProceed: boolean;
  optionSelected: any;
  getConditionColor = AssetConditionIndicatorComponent.getConditionColor;

  constructor(
    public dialogRef: MatDialogRef<ConfirmAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressData,
    private readonly translate: TranslocoService
  ) {
    // do not show proceed button when suspicious data found
    if (data.addressComponents.find((ac) => ac.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS')) {
      this.hideProceed = true;
    }
  }

  translateProblems(confirmationLevel: string, componentType: string, unexpected?: boolean): string {
    let text = '';
    if (componentType.includes('administrative_area_level')) {
      componentType = componentType.slice(0, -2);
    }
    switch (componentType) {
      case 'route':
        text += `${this.translate.translate('google.address.number')} `;
        break;
      case 'street_number':
        text += `${this.translate.translate('google.address.street')} `;
        break;
      case 'postal_code':
        text += `${this.translate.translate('google.address.postal')} `;
        break;
      case 'locality':
        text += `${this.translate.translate('google.address.city')} `;
        break;
      case 'country':
        text += `${this.translate.translate('google.address.country')} `;
        break;
      case 'premise':
        text += `${this.translate.translate('google.address.premise')} `;
        break;
      case 'subpremise':
        text += `${this.translate.translate('google.address.subpremise')} `;
        break;
      case 'administrative_area_level':
        text += `${this.translate.translate('google.address.admin-level')} `;
        break;
      default:
        text += `${componentType} `;
        break;
    }
    switch (confirmationLevel) {
      case 'UNCONFIRMED_BUT_PLAUSIBLE':
        text += `${this.translate.translate('google.confirm.unconfirmed-plausible')}`;
        break;
      case 'UNCONFIRMED_AND_SUSPICIOUS':
        text += `${this.translate.translate('google.confirm.unconfirmed-suspicious')}`;
        break;
      case 'CONFIRMED':
        if (unexpected) text += `${this.translate.translate('google.confirm.confirmed-unexpected')}`;
        break;
    }
    return text;
  }

  onClick(proceed?: boolean): void {
    this.dialogRef.close(proceed);
  }
}
