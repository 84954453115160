import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AbstractCategoryTree } from 'src/app/services/asset.service';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class SearchSelectComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() selectionChange = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  @Output() selectedItemChange = new EventEmitter<any>();

  @Input() autoWidth: boolean;
  @Input() subscriptSizing = 'dynamic';
  @Input() width125px: boolean;
  @Input() width100: boolean;
  @Input() options: any[];
  @Input() required: boolean;
  @Input() multiple = false;

  @Input() optionsTemplate: TemplateRef<any>;
  @Input() placeholder: string;
  @Input() showClear = false;
  @Input() parentOnlyAsGroup = false;
  @Input() filterAttribute = 'columnAttribute';

  /** An attribute what value to use inside the options object as mat-option */
  @Input() valueAttribute: string | false = false;

  optionsFiltered: any[];
  optionsFilterCtrl = new UntypedFormControl();

  selectedItemValue: any;

  @Input() get selectedItem(): any {
    return this.selectedItemValue;
  }
  set selectedItem(val: any) {
    console.log(val);
    const old = this.selectedItemValue;
    this.selectedItemValue = val;
    if (old !== this.selectedItemValue) {
      this.selectedItemChange.emit(this.selectedItemValue);
    }
  }

  ngOnInit(): void {
    this.optionsFilterCtrl.valueChanges.subscribe(() => {
      this.filterOptions();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit(): void {
    if (!this.options) {
      return;
    }
    this.optionsFiltered = this.options.slice().map((e) => this.removeParentFromTree(e));
  }

  private filterOptions(): void {
    // get the search keyword
    let search = this.optionsFilterCtrl.value;
    if (!search) {
      this.optionsFiltered = this.options.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.optionsFiltered = this.options.slice().filter(this.filterOptionsRecursive(search));
  }

  private filterOptionsRecursive(search: string): (c: any) => boolean {
    return (c: any) => {
      const matchesSelf = c[this.filterAttribute].toLowerCase().indexOf(search) > -1;
      if (!matchesSelf && c.children) {
        if (!c._childrenOriginal) {
          c._childrenOriginal = c.children.slice();
        }
        c.children = c._childrenOriginal.filter(this.filterOptionsRecursive(search)).slice();
      }
      return matchesSelf || c.children?.length;
    };
  }

  private removeParentFromTree(e: any | AbstractCategoryTree<any>): any | AbstractCategoryTree<any> {
    if (e.parent) {
      delete e.parent;
    }
    if (e.children) {
      e.children.map((x) => this.removeParentFromTree(x));
    }
    return e;
  }
}
