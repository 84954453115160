import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FilterListMenuItem, FilterListMenuService } from '../../../services/filter-list-menu.service';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-filter-list-menu-outlet',
  templateUrl: './filter-list-menu-outlet.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    MatListModule,
    MatButtonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class FilterListMenuOutletComponent implements OnInit, OnDestroy {
  @Input() for: string;
  @Input() menuEntry: string;
  @Output() itemSelected = new EventEmitter();

  title: string;

  items: FilterListMenuItem[] = [];

  subscriptions: {
    createNewEnabled?: Subscription;
    items?: Subscription;
  } = {};

  createNewIsEnabled = false;
  creating = false;

  constructor(
    private filterListService: FilterListMenuService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.subscriptions.createNewEnabled = this.filterListService.createNewIsEnabled(this.for).subscribe((b) => {
      this.createNewIsEnabled = b;
    });
  }

  getData() {
    if (this.subscriptions.items) this.subscriptions.items.unsubscribe();

    this.subscriptions.items = this.filterListService.getItems().subscribe((i) => {
      if (i[this.for]) {
        this.items = i[this.for].filter((e) => e.menuEntry?.includes(this.menuEntry));
      } else {
        this.items = [];
      }
    });
  }

  requestEdit(item: FilterListMenuItem): void {
    this.filterListService.requestEdit(item, this.for);
    this.creating = false;
    this.title = undefined;
  }

  async delete(item: FilterListMenuItem) {
    await this.filterListService.requestDeleteItem(this.for, item);
    this.getData();
  }

  async create() {
    if (!this.title) return;

    this.creating = false;
    await this.filterListService.createNewItemFromCurrentView(this.for, this.title, `${this.menuEntry}-custom`);
    this.getData();
    this.title = undefined;
  }

  ngOnDestroy(): void {
    for (const item of Object.values(this.subscriptions)) {
      if (item) {
        item.unsubscribe();
      }
    }
  }
}
