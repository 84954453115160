<ng-container *transloco="let t">
  @if (showHeader) {
    <app-header
      class="mat-elevation-z6"
      [class.hidden]="navigating"
      [navbarOpen]="showDrawer && sideNav?.opened ?? true"
      [isMobile]="isMobileView"
      (navbarToggled)="navBarToggle()"
    ></app-header>
    <br />
  }
  <!-- Creates a layout with a left-positioned sidenav and explicit content. -->
  <mat-sidenav-container class="main-container" [class.content-page]="showDrawer">
    @if (showDrawer && isLoggedIn) {
      <mat-sidenav
        [mode]="isMobileView ? 'over' : 'side'"
        [opened]="!isMobileView || isOpened"
        (opened)="isOpened = true"
        (closed)="isOpened = false"
        #sideNav
      >
        <app-navigation [staticPageMenu]="staticPageMenu" (itemSelected)="onMenuItemSelected()" />
      </mat-sidenav>
    }
    <mat-sidenav-content>
      @if (navigating) {
        <mat-progress-bar mode="indeterminate" style="position: absolute; top: 40%; left: 0"></mat-progress-bar>
      }
      @if (!awaitingLogin) {
        <div [class.navigating]="navigating" [class.full-width]="isMobileView" class="main-layout">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>

  @if (showReload) {
    <div class="floating-btn">
      <a mat-raised-button color="accent" (click)="reload()">
        Update! (Auto update in {{ reloadTime }}s)
        <mat-icon>refresh</mat-icon>
      </a>
    </div>
  }
</ng-container>
