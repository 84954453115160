import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { UpdateCounterComponent } from '../components/dialogs/update-counter/update-counter.component';
import { filter, map } from 'rxjs/operators';
import { ConfirmComponent } from '../components/dialogs/confirm-dialog/confirm.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly VAPID_PUBLIC_KEY = 'BKYV6LXVF-6a792HrvHpResa4-wLTrd8bo9O5JJFdLAxYlucXPDeW07SAH0sDhcEHnfo9ABcrJ7O-lpO0SF-LK8';
  subscription: PushSubscription;
  private snackbarQueue: MatSnackBarRef<SimpleSnackBar>[] = [];

  constructor(
    router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private swUpdate: SwUpdate,
    private swPush: SwPush
  ) {
    this.setupUpdates();
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      console.log('clicked', action, notification);
      switch (action) {
        case 'open':
          router.navigateByUrl(notification.data.url);
          break;
        default:
          router.navigateByUrl(notification.data.url);
      }
    });
  }

  success(msg: string, duration?: number): Observable<void> {
    duration = duration ? duration : 3;
    return this.snackBar
      .openFromComponent(SnackbarComponent, {
        horizontalPosition: 'right',
        duration: duration * 1000,
        panelClass: 'bg-success',
        data: { message: msg, duration }
      })
      .onAction();
  }

  message(msg: string, duration?: number): Observable<void> {
    duration = duration ? duration : 3;
    return this.snackBar
      .openFromComponent(SnackbarComponent, {
        horizontalPosition: 'right',
        duration: duration * 1000,
        panelClass: 'bg-notification',
        data: { message: msg, duration }
      })
      .onAction();
  }

  /**
   * This logs an error to the mat SnackBar. It displays a toast message.
   *
   * @param msg
   * @param duration in seconds
   * @returns
   */
  error(msg: string, duration?: number): Observable<void> {
    console.error(msg);
    duration = duration ? duration : 15;
    return this.snackBar
      .openFromComponent(SnackbarComponent, {
        horizontalPosition: 'right',
        duration: duration * 1000,
        panelClass: 'bg-error-85',
        data: { message: msg, duration }
      })
      .onAction();
  }

  confirmDialog(data: { title: string; body: string }): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmComponent, { data });
    return dialogRef.afterClosed().toPromise();
  }

  setupUpdates(): void {
    if (this.swUpdate.isEnabled) {
      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        }))
      );
      updatesAvailable.subscribe(() => {
        this.promptForUpdate();
      });
      this.swUpdate.checkForUpdate();
    } else {
      console.log('swUpdate not enabled');
    }
  }

  setupPush(): Observable<PushSubscription> {
    return new Observable<PushSubscription>((observer) => {
      if (this.swPush.isEnabled) {
        if (this.subscription) {
          observer.next(this.subscription);
          observer.complete();
          return;
        }
        this.swPush
          .requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
          })
          .then(
            (sub) => {
              observer.next(sub);
              observer.complete();
              console.log('Push Subscription', JSON.stringify(sub));
            },
            (err) => {
              observer.error(err);
              this.error('Benachrichtung konnte nicht registriert werden!');
              console.error('error registering for push', err);
            }
          );
      } else {
        this.error('Pushbenachrichtigung sind deaktiviert!');
        console.error('Push not enabled');
        observer.error(new Error('Push not enabled'));
      }
    });
  }

  getSubscription(): Observable<PushSubscription> {
    return new Observable((observer) => {
      if (this.subscription) {
        observer.next(this.subscription);
        observer.complete();
      } else if (this.swPush.isEnabled) {
        this.swPush.subscription.subscribe((sub) => {
          this.subscription = sub;
          observer.next(this.subscription);
          observer.complete();
        });
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  private promptForUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      this.dialog.open(UpdateCounterComponent);
    });
  }
}
