<div *transloco="let t">
  @for (item of items; track item) {
    <div class="flex align-center space-between">
      <button
        mat-list-item
        [queryParams]="item.getParamsAsQueryParams()"
        routerLink="{{ for }}"
        (click)="itemSelected.emit()"
        >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">
          @if (item.menuEntry?.includes('custom')) {
            patient_list
          } @else {
            format_list_numbered
          }
        </mat-icon>
        {{ t(item.title) }}
      </button>
      @if (item.menuEntry?.includes('custom')) {
        <button mat-icon-button (click)="delete(item)">
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
      }
    </div>
  }
  <div class="flex align-center space-between">
    @if (!creating) {
      <button mat-list-item (click)="creating = true" [disabled]="!createNewIsEnabled">
        <mat-icon matListItemIcon class="margin-xxs-right">add</mat-icon>
        {{ t('dynamic-table.create-new-menu-item') }}
      </button>
    } @else {
      <mat-list-item>
        <mat-icon matListItemIcon class="margin-xxs-right">backup_table</mat-icon>
        <mat-form-field class="no-subscript">
          <input
            matInput
            style="max-width: 160px"
            [(ngModel)]="title"
            (keydown.enter)="create()"
            appAutofocus
            placeholder="{{ t('dynamic-table.name-placeholder') }}"
          />
          <button mat-icon-button matSuffix (click)="create()" [disabled]="!title"><mat-icon>save</mat-icon></button>
        </mat-form-field>
      </mat-list-item>
    }
  </div>
</div>
