import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable({ providedIn: 'root' })
export class TranslationBackendService {
  constructor(private readonly backendService: BackendService) {}

  /**
   * Receive a translation for a specific key for PE translations. This is useful if there need to be a predefined_datafield_value in the start step of a process.
   *
   * Therefore we need to translate the name(s) before providing it to the processhandler. See smv.component for example.
   */
  getPETranslationByKey(key: string, type: string, lang: string) {
    return this.backendService.get<'processEngineDefinition/getTranslation'>('process-engine/processDefinition/translation', {
      query: { key, type, lang }
    });
  }
}
