import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../services/backend.service';

@Pipe({
  name: 'roleName',
  standalone: true
})
export class RoleNamePipe implements PipeTransform {
  constructor(private readonly backendService: BackendService) {}

  transform(roleId: number): Observable<string> {
    return new Observable<string>((observer) => {
      this.backendService.get<'user/getRoles'>('user/roles').subscribe((roles) => {
        const role = roles.find((_r) => _r.id === Number(roleId));
        if (role) {
          observer.next(role.name);
        } else {
          observer.next('-');
        }
        observer.complete();
      });
    });
  }
}
