<ng-container *transloco="let t">
  <mat-toolbar color="primary" class="flex space-between">
    <div class="flex align-center">
      <button
        mat-icon-button
        *ngIf="isMobile && isLoggedIn && ![5, 9, 13].includes(roleId)"
        (click)="navbarToggled.emit(!navbarOpen)"
      >
        <mat-icon *ngIf="navbarOpen">menu_open</mat-icon>
        <mat-icon *ngIf="!navbarOpen">menu</mat-icon>
      </button>
      <a mat-button routerLink="/" class="hidden-xs">
        <span class="madinu-text flex align-center"> <img src="/assets/logo/company_logo.png" /></span>
      </a>
      <a mat-button routerLink="/" class="visible-xs">
        <span class="madinu-text flex align-center">
          <img class="radius-4px" src="/assets/icons/icon-192x192.png" />
        </span>
      </a>
    </div>
    <div>
      <button mat-button [matMenuTriggerFor]="languagesSelector">
        <mat-icon>translate</mat-icon> <ng-container *ngIf="!isMobile"> {{ t('menu.top.language') }}</ng-container>
      </button>
      <mat-menu #languagesSelector>
        <ng-container *ngFor="let lang of languages">
          <button mat-menu-item (click)="useLanguage(lang.value)">{{ lang.label }}</button>
        </ng-container>
        <button mat-menu-item (click)="translateNow()" *ngIf="roleId === 8">Translate</button>
      </mat-menu>

      <mat-menu #supportMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="onSupportClicked()"><mat-icon>edit</mat-icon> {{ t('support.write') }}</button>
        <button *ngIf="isLoggedIn && [1, 8].includes(roleId)" mat-menu-item routerLink="/support/tickets">
          <mat-icon>receipt_long</mat-icon> {{ t('support.overview') }}
        </button>
        <button *ngIf="isLoggedIn && [1, 8].includes(roleId)" mat-menu-item routerLink="/support/milestones">
          <mat-icon>task</mat-icon> {{ t('support.milestones') }}
        </button>
        <button mat-menu-item (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
        @if (roleId !== 13) {
          <button mat-menu-item (click)="emptyCart()"><mat-icon>delete</mat-icon> Empty Cart</button>
        }
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="supportMenu">
        <mat-icon>contact_support</mat-icon> <ng-container *ngIf="!isMobile"> Support</ng-container>
        <!-- <mat-icon>more_vert</mat-icon> -->
      </button>

      <app-user-avatar [inspectorTasks]="openTasks"></app-user-avatar>
    </div>
  </mat-toolbar>
</ng-container>
