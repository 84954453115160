import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Step } from 'src/app/interfaces/process/step.interface';
import {
  AllStepsPerformedEvent,
  ProcessHandlerDialogComponent
} from 'src/app/process-engine/process/process-handler-dialog/process-handler-dialog.component';
import { IdToNamePipe } from 'src/app/pipes/id-to-name.pipe';
import { ProcessResultDialogComponent } from '../dialogs/process-result/process-result-dialog.component';
import { ExpansionTableData } from './interfaces/expansion-table-data.interface';
import { BackendService, subscriptionIsActive } from 'src/app/services/backend.service';

@Component({
  selector: 'app-expansion-table',
  templateUrl: './expansion-table.component.html',
  styleUrls: ['./expansion-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    IdToNamePipe,
    RouterModule
  ]
})
export class ExpansionTableComponent {
  @Input() tableData: ExpansionTableData[];
  @Input() resultDialog: boolean;
  @Input() processDialog: boolean;
  @Input() subscription: Subscription;
  @Input() excludeProcessByRoleId: number;
  @Input() customEvent: boolean;
  @Input() detailedHeader: boolean;
  @Output() stepPerformed = new EventEmitter();
  @Output() customEventEmitter = new EventEmitter();
  @Output() deleteEvent = new EventEmitter<ExpansionTableData>();

  subscriptionIsActive = subscriptionIsActive;

  constructor(
    private readonly dialog: MatDialog,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly backendService: BackendService
  ) {}

  proceedProcess(stepId: number, processId: number) {
    this.backendService.get<'processEngineProcess/getStep'>('process-engine/process/{processId}/step/{stepId}', {
      path: { processId, stepId }
    }).subscribe((s) => {
      this.openProcessHandlerDialog(s as unknown as Step); // TODO: Fix type
    });
  }

  openProcessResult(processId: number) {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    this.dialog.open(ProcessResultDialogComponent, {
      ...dimensions,
      data: { processId }
    });
  }

  private openProcessHandlerDialog(step: Step): void {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    const noTabs = ['Visits', 'Emergency-Report'].includes(step.process.ProcessDefinition.phase);

    const dialogRef = this.dialog.open(ProcessHandlerDialogComponent, {
      ...dimensions,
      data: {
        steps: [step],
        processStartOptions: {},
        noTabs
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.stepPerformed.emit(result);
      window.setTimeout(() => {}, 500);
      if (result instanceof AllStepsPerformedEvent) {
        console.log('all performed');
        this.stepPerformed.emit({ allPerformed: true });
      }
    });
  }
}
