import { Pipe, PipeTransform } from '@angular/core';
import { Capability } from 'src/app/interfaces/user/capability.interface';

@Pipe({
  name: 'capabilityCheck',
  standalone: true
})
export class CapabilityCheckPipe implements PipeTransform {
  transform(capabilities: Capability[], requiredCapability: string): boolean {
    if (!capabilities) return false;

    return capabilities.includes(requiredCapability) || capabilities.includes(Capability.All);
  }
}
