import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ProcessHandlerService } from './process-handler.service';
import { IProcessStartOptions } from 'src/app/interfaces/process/process.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { DataFieldTranslation } from 'src/app/interfaces/process/data-field.interface';
import { ProcessHandlerComponent } from '../process-handler/process-handler.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
    selector: 'app-process-handler-dialog',
    templateUrl: './process-handler-dialog.component.html',
    styleUrls: ['./process-handler-dialog.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, NgIf, MatDialogTitle, MatIcon, MatIconButton, MatDialogClose, MatDialogContent, MatProgressBar, ProcessHandlerComponent, MatDialogActions, MatButton, NgFor, DatePipe]
})
export class ProcessHandlerDialogComponent implements OnInit {
  steps: Step[];

  proceedButton: boolean;
  lastStep: boolean;
  lastStepSingleStepProcess: boolean;
  backButton: boolean;
  choices: any;
  actionPerformed: boolean;
  showDescriptionStepTitle = false;

  progress: number;

  constructor(
    public dialogRef: MatDialogRef<ProcessHandlerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      steps: Step[];
      processStartOptions?: IProcessStartOptions;
      noTabs?: boolean;
      translations?: DataFieldTranslation[];
      showDescriptionStepTitle?: boolean;
    },
    private readonly processHandlerService: ProcessHandlerService
  ) {
    this.steps = JSON.parse(JSON.stringify(this.data.steps));
    this.showDescriptionStepTitle = data.showDescriptionStepTitle;
  }

  ngOnInit(): void {
    this.processHandlerService.processProgress.subscribe((progress) => {
      setTimeout(() => {
        this.progress = progress;
      });
    });
    this.processHandlerService.stepButtons.subscribe((e) => {
      setTimeout(() => {
        this.proceedButton = e.proceed;
        this.lastStep = e.lastStep;
        this.lastStepSingleStepProcess = e.lastStepSingleStepProcess;
        this.backButton = e.back;
        this.choices = e.choices;
      }, 0);
    });
  }

  choiceSelected(choiceTitle: string) {
    this.actionPerformed = true;
    this.processHandlerService.choiceSelected.next(choiceTitle);
  }

  proceed() {
    this.actionPerformed = true;
    this.processHandlerService.proceedClicked.next(true);
  }

  back() {
    this.actionPerformed = true;
    this.processHandlerService.backClicked.next(true);
  }

  onActionPerformed() {
    this.actionPerformed = true;
  }

  onAllStepsPerformed(lastStep: Step) {
    this.dialogRef.close(new AllStepsPerformedEvent(lastStep));
  }
}

export class AllStepsPerformedEvent {
  data?: Step;
  constructor(data?: Step) {
    this.data = data;
  }
}
