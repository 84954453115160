import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceDialogSize } from './interfaces/device-dialog-size.interface';
import { AssetService } from 'src/app/services/asset.service';
import { UserService } from 'src/app/services/user.service';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { AssetAttributeValue } from 'src/app/interfaces/assets/asset-attribute-value.interface';
import { DataField } from 'src/app/interfaces/process/data-field.interface';
import { Input, TypeData } from 'src/app/interfaces/process/step-definition.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { UserLogin } from 'src/app/interfaces/user/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProcessHandlerService {
  proceedClicked = new Subject<boolean>();
  backClicked = new Subject<boolean>();
  stepButtons = new Subject<{
    proceed?: boolean;
    lastStep?: boolean;
    lastStepSingleStepProcess?: boolean;
    back?: boolean;
    choices?: any;
  }>();
  choiceSelected = new Subject<string>();
  processProgress = new Subject<number>();

  constructor(
    private readonly mobileService: MobileViewService,
    private userService: UserService,
    private readonly assetService: AssetService
  ) {}

  // ToDo: get this from backend
  getProcessStepsOrder(processDefinition: number): number[][] {
    // ToDo: get this from backend
    let processSteps: number[][] = [];
    if (processDefinition === 4) {
      processSteps = [[20], [22], [23], [173], [174], [175], [171], [24], [176]];
    } else if (processDefinition === 5) {
      processSteps = [
        [27, 28],
        [28, 31],
        [30, 33],
        [32, 35],
        [34, 37],
        [36, 38],
        [39, 40],
        [41, 42],
        [43, 44]
      ];
    } else if (processDefinition === 6) {
      processSteps = [[58], [136]];
    } else if (processDefinition === 7) {
      if (environment.environment.includes('deymann')) {
        processSteps = [[83], [80, 84, 85, 86, 87], [332], [333], [334]];
      } else {
        processSteps = [[83], [80, 84, 85, 86, 87]];
      }
    } else if (processDefinition === 9) {
      processSteps = [[64]];
    } else if (processDefinition === 10) {
      processSteps = [[66]];
    } else if (processDefinition === 11) {
      processSteps = [[67]];
    } else if (processDefinition === 12) {
      processSteps = [[68]];
    } else if (processDefinition === 13) {
      processSteps = [[69]];
    } else if (processDefinition === 14) {
      processSteps = [[70]];
    } else if (processDefinition === 15) {
      processSteps = [[71]];
    } else if (processDefinition === 16) {
      processSteps = [[97], [98], [110], [111], [112], [129], [299], [128], [113]];
    } else if (processDefinition === 17) {
      processSteps = [[224]];
    } else if (processDefinition === 18) {
      processSteps = [[163], [165], [166], [177], [178], [179], [172], [167], [180]];
    } else if (processDefinition === 19) {
      processSteps = [[324], [246, 325, 326, 327], [247], [298]];
    } else if (processDefinition === 20) {
      processSteps = [[253]];
    } else if (processDefinition === 21) {
      processSteps = [[256], [259]];
    } else if (processDefinition === 22) {
      processSteps = [[274]];
    } else if (processDefinition === 23) {
      processSteps = [[285], [287], [290]];
    } else if (processDefinition === 24) {
      processSteps = [[300]];
    } else if (processDefinition === 25) {
      processSteps = [[301]];
    } else if (processDefinition === 26) {
      processSteps = [[314]];
    } else if (processDefinition === 27) {
      processSteps = [[335], [336], [337], [338]];
    }

    return processSteps;
  }

  getProcessSteps(processDefinitionId: number): number[] {
    const processSteps = this.getProcessStepsOrder(processDefinitionId);
    return processSteps.flat();
  }

  isSingleStepVisit(step: Step): boolean {
    const processDefinitionId = step.process.process_definition_id;
    const allSteps = this.getProcessStepsOrder(processDefinitionId);
    if (allSteps.length === 1) {
      return true;
    }
    return false;
  }

  /**
   * Retrieves the actual progression of the process, to visualize in e.g. a progress bar
   */
  getStepProgression(step?: Step): void {
    if (!step) {
      this.processProgress.next(100);
      return;
    }
    const processSteps = this.getProcessStepsOrder(step.process.process_definition_id);
    let result: { length: number; stepIndex: number };
    for (let i = 0; i < processSteps.length; i++) {
      if (processSteps[i].includes(step.step_definition_id)) {
        result = { length: processSteps.length, stepIndex: i };
        break;
      }
    }
    if (result) {
      const currentProgression = result.stepIndex + 1;
      const progress = (currentProgression / processSteps.length) * 100;

      this.processProgress.next(Math.floor(progress));
    } else {
      this.processProgress.next(100);
    }
  }

  getCritEquInitialFieldValues(assetAttributeValues: AssetAttributeValue[]) {
    let ownVessel: boolean;
    let inspectorEmail: string;
    let inspectorPhone: string;
    // find own vessel attribute values
    if (assetAttributeValues.find((e) => [175, 176, 177, 178, 190].includes(e.assetAttributeId))?.value === 'GEFO') {
      ownVessel = true;
    }
    // find attributes for inspector email and phone
    if (assetAttributeValues.find((e) => [179, 180, 181, 182, 191].includes(e.assetAttributeId))?.value) {
      inspectorEmail = assetAttributeValues.find((e) => [179, 180, 181, 182, 191].includes(e.assetAttributeId)).value;
    }
    if (assetAttributeValues.find((e) => [195, 196, 197, 198, 199].includes(e.assetAttributeId))?.value) {
      inspectorPhone = assetAttributeValues.find((e) => [195, 196, 197, 198, 199].includes(e.assetAttributeId)).value;
    }

    return { ownVessel, inspectorEmail, inspectorPhone };
  }

  checkPhoneEmail(value: any) {
    const phoneNumberRegex = /^(00|\+|0)\s?\d+(\s?\d+)?/;
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (phoneNumberRegex.test(value)) {
      return 'tel';
    } else if (emailRegex.test(value)) {
      return 'mail';
    } else {
      return null;
    }
  }

  // ToDo: Move to mobileviewService or somewhere else
  /**
   * Checks if the app runs on a Mobile Device and returns specific Dialog dimensions.
   *
   * @returns dimensions as an Object
   */
  getDialogScreenSize(): DeviceDialogSize {
    const isMobile = this.mobileService.detectMobileDevice();

    let dimensions: DeviceDialogSize;
    if (isMobile) {
      dimensions = {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'mat-dialog-override'
      };
    } else {
      dimensions = { width: '95%', maxWidth: '750px', maxHeight: '95%' };
    }

    return dimensions;
  }

  /**
   * Transforms the data fields based on specific conditions and returns the updated step.
   *
   * Barge/Vessel is transformed to clear text name
   *
   * Visitor is transformed to userId to be used in select e.g.
   *
   * @param dataFields - The input data fields to transform.
   * @param data - The data object containing the data fields. ToDo: define a type
   * @param step - The step object to update with transformed data fields.
   * @returns The updated step object with transformed data fields.
   */
  async transformDataFields(dataFields: Input[], data: any, step: Step<TypeData>): Promise<Step<TypeData>> {
    try {
      if (!dataFields) throw new Error('dataFields is undefined');

      for (const element of dataFields) {
        const dataField: DataField = data.DataFields.find((e) => e.data_field_definition_id === element.id);

        if (dataField?.name.includes('Barge/Vessel')) {
          if (Number(dataField.value)) {
            const asset = await this.assetService.getAsset(Number(dataField.value)).toPromise();
            dataField.value = asset.name;
          }
        }

        if (dataField?.name.includes('Visitor')) {
          dataField.value = await this.getUserIdByDataFieldValue(dataField.value);
        }

        if (dataField) {
          const index = step.stepDefinition.type_data.inputs.findIndex((e) => e.id === element.id);
          if (index > -1) {
            step.stepDefinition.type_data.inputs[index].value = dataField.value;
          }
        }
      }

      return step;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Gets the userId by the user name.
   *
   * We changed the datafield value Visitor in SMV from name to id. To handle the 'old' visits we need this conversion.
   *
   * @param value - Name or id of the user
   * @param dataSource - Optional, if set no further API call is done
   * @returns userId as number
   */
  async getUserIdByDataFieldValue(value: string, dataSource?: UserLogin[]): Promise<number> {
    let userId: number;
    if (Number(value)) {
      userId = Number(value);
    } else {
      const userNameParts: string[] = value.split(' ');
      const foreName = userNameParts[0];
      const lastName = userNameParts[userNameParts.length - 1] || '';
      if (!dataSource) {
        dataSource = await this.getDataSource(foreName, lastName);
      }

      const user = dataSource.find((e) => e.user.forename.includes(foreName) && e.user.surname.includes(lastName));
      if (user) {
        userId = user.userId;
      } else {
        // user is not in provided datasource, look in other users
        dataSource = await this.getDataSource(foreName, lastName);
        if (!dataSource) return null;
        userId = dataSource[0].userId;
      }
    }

    return userId;
  }

  async getUserNameById(userId: number, dataSource?: UserLogin[]): Promise<string> {
    if (!dataSource) {
      dataSource = await this.userService.getAllUserLogins({ userId }, 1).toPromise();
    }

    const login = dataSource.find((e) => e.userId === userId);
    if (login) {
      return `${login.user.forename} ${login.user.surname}`;
    } else {
      return null;
    }
  }

  private async getDataSource(foreName: string, lastName: string): Promise<UserLogin[]> {
    return await this.userService
      .getAllUserLogins(
        { '$user.forename$': { $like: `${foreName}%` }, '$user.surname$': { $like: `%${lastName}` } },
        1
      )
      .toPromise();
  }
}
