import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AbstractStepHandleComponent } from '../abstract-step-handle/abstract-step-handle.component';
import { ActionResult } from 'src/app/interfaces/process/action-result.interface';
import { ProcessService } from 'src/app/services/process.service';
import { NgIf } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'app-step-handle-input-watcher',
    templateUrl: './step-handle-input-watcher.component.html',
    styles: [],
    standalone: true,
    imports: [MatProgressBar, NgIf]
})
export class StepHandleInputWatcherComponent extends AbstractStepHandleComponent implements OnInit, OnDestroy {
  @Output() stepPerformed = new EventEmitter<ActionResult>();

  now: Date;
  timerInterval: number;

  constructor(private processService: ProcessService) {
    super();
  }

  ngOnInit(): void {
    this.now = new Date();

    this.timerInterval = window.setInterval(() => {
      this.now = new Date();
    }, 1000);

    this.performCheck();
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
  }

  private performCheck() {
    if (this.step.executed) {
      clearInterval(this.timerInterval);
      return;
    }
    this.processService.performStep(this.step, {}).subscribe((p) => {
      if (p.result === false && this.step.stepDefinition.type === ProcessService.TYPES.INPUT_WATCHER) {
        window.setTimeout(() => {
          this.performCheck();
        }, 3000);
      } else {
        if ((p as any).state === false) {
          // the input checker is executed without success
          this.stepPerformed.emit(p);
          clearInterval(this.timerInterval);
        }
        this.stepPerformed.emit(p);
        clearInterval(this.timerInterval);
      }
    });
  }
}
