export const COLOR_CODES: string[] = [
  '#686360',
  '#C7DB51',
  '#C57C9B',
  '#E74935',
  '#ffd700',
  '#97CFDF',
  '#DB6C37',
  '#D7A2D9',
  '#74EB1B',
  '#949a2b',
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#02e2de',
  '#b15928'
];

export interface JacketSize {
  size: string;
  taille: string;
  chest: string;
  height?: string;
  hip?: string;
  inseam?: string;
}
export const JACKET_SIZE_MEN = [
  { size: 'XS', taille: '66-74', chest: '78-86', height: '164-172' },
  { size: 'S', taille: '74-82', chest: '86-94', height: '164-172' },
  { size: 'M', taille: '82-90', chest: '94-102', height: '172-180' },
  { size: 'L', taille: '90-106', chest: '102-110', height: '172-180' },
  { size: 'XL', taille: '98-106', chest: '110-118', height: '180-188' },
  { size: '2XL', taille: '106-117', chest: '118-129', height: '180-188' },
  { size: '3XL', taille: '117-129', chest: '129-141', height: '188-196' },
  { size: '4XL', taille: '129-141', chest: '141-153', height: '188-196' }
];

export const MULTINORM_MEN = [
  { chest: '86-90', height: '172-176', size: '44' },
  { chest: '90-94', height: '174-178', size: '46' },
  { chest: '94-98', height: '176-180', size: '48' },
  { chest: '98-102', height: '172-182', size: '50' },
  { chest: '102-106', height: '180-184', size: '52' },
  { chest: '106-110', height: '182-186', size: '54' },
  { chest: '110-114', height: '184-188', size: '56' },
  { chest: '114-118', height: '186-190', size: '58' },
  { chest: '118-123', height: '188-192', size: '60' },
  { chest: '123-129', height: '190-194', size: '62' },
  { chest: '129-135', height: '192-196', size: '64' }
];

export const JACKET_SIZE_HAVEP: JacketSize[] = [
  { size: '40', taille: '71', chest: '101', inseam: '76' },
  { size: '42', taille: '74', chest: '105', inseam: '77' },
  { size: '44', taille: '77', chest: '109', inseam: '78' },
  { size: '46', taille: '80', chest: '113', inseam: '79' },
  { size: '48', taille: '84', chest: '117', inseam: '80' },
  { size: '50', taille: '87', chest: '121', inseam: '81' },
  { size: '52', taille: '91', chest: '126', inseam: '82' },
  { size: '54', taille: '94', chest: '130', inseam: '83' },
  { size: '56', taille: '98', chest: '134', inseam: '84' },
  { size: '58', taille: '101', chest: '138', inseam: '85' },
  { size: '60', taille: '105', chest: '142', inseam: '86' },
  { size: '62', taille: '108', chest: '146', inseam: '87' },
  { size: '64', taille: '112', chest: '150', inseam: '87' },
  { size: '66', taille: '116', chest: '154', inseam: '87' },
  { size: '68', taille: '120', chest: '158', inseam: '87' },
  { size: '70', taille: '124', chest: '162', inseam: '87' },
  { size: '72', taille: '128', chest: '166', inseam: '87' },
  { size: '74', taille: '132', chest: '170', inseam: '87' }
];

export interface CombinedSizes {
  bodywear: string;
  eur: string;
}

// multinormWomen
