<mat-form-field
  [subscriptSizing]="subscriptSizing"
  [class.form-field-small]="autoWidth"
  [class.width-125px]="autoWidth && !width100"
  [class.width-100]="width100"
>
  @if (!autoWidth) {
    <mat-label>{{ placeholder }}</mat-label>
  }
  <mat-select
    (selectionChange)="selectionChange.emit($event)"
    #select
    [placeholder]="placeholder"
    [multiple]="multiple"
    [(value)]="selectedItem"
    [required]="required"
  >
    <mat-option style="background: white">
      <ngx-mat-select-search [formControl]="optionsFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    @for (option of optionsFiltered; track option) {
      @if (!option.children?.length) {
        <mat-option [value]="valueAttribute ? option[valueAttribute] : option">
          <ng-template
            [ngTemplateOutlet]="optionsTemplate || defaultOptionsTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-template>
        </mat-option>
      }
      @if (option.children) {
        <mat-optgroup [label]="option.name" style="font-weight: 600">
          @if (!parentOnlyAsGroup && (!valueAttribute || option[valueAttribute])) {
            <mat-option [value]="valueAttribute ? option[valueAttribute] : option">Alle</mat-option>
          }
          @for (childOption of option.children; track childOption) {
            <mat-option [value]="valueAttribute ? childOption[valueAttribute] : childOption">
              <ng-template
                [ngTemplateOutlet]="optionsTemplate || defaultOptionsTemplate"
                [ngTemplateOutletContext]="{ $implicit: childOption }"
              ></ng-template>
            </mat-option>
          }
        </mat-optgroup>
      }
    }
  </mat-select>
  @if (selectedItem && showClear) {
    <button
      mat-button
      matSuffix
      aria-label="Clear"
      (click)="selectedItem = undefined; clear.emit()"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>

<ng-template #defaultOptionsTemplate let-option>{{ option }}</ng-template>

<ng-template #recursiveMatOptionTemplate let-options>
  @for (option of options; track option) {
    <mat-option [value]="valueAttribute ? option[valueAttribute] : option">
      <ng-template
        [ngTemplateOutlet]="optionsTemplate || defaultOptionsTemplate"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-template>
    </mat-option>
    @if (option.children) {
      <mat-optgroup [label]="option.name">
        <ng-template
          [ngTemplateOutlet]="recursiveMatOptionTemplate"
          [ngTemplateOutletContext]="{ $implicit: option.children }"
        ></ng-template>
      </mat-optgroup>
    }
  }
</ng-template>
