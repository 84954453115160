import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { InspectorService } from 'src/app/views/inspector/tasks/inspector.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InstallDialogComponent } from './not-pwa-dialog/install-dialog.component';
import { TestAppDialogComponent } from './not-live-app-dialog/test-app-dialog.component';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { RouteService } from 'src/app/route.service';
import { BackendService } from 'src/app/services/backend.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule
  ]
})
export class LoginComponent implements OnInit {
  loading = false;

  title = environment.appTitle;

  version = localStorage.getItem('app_version') || '1.0.0';
  error: string;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private readonly backendService: BackendService,
    private readonly jwtService: JwtService,
    private router: Router,
    private readonly mobileService: MobileViewService,
    private readonly dialog: MatDialog,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly inspectorService: InspectorService,
    private readonly notify: NotificationService,
    private readonly routeService: RouteService
  ) {}

  ngOnInit(): void {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    if (environment.environment.includes('staging')) {
      this.dialog.open(TestAppDialogComponent, { ...dimensions });
    } else {
      if (!this.mobileService.detectIfPWA() && this.mobileService.detectMobileDevice()) {
        if (!localStorage.getItem('first_steps_show')?.includes('true')) {
          this.dialog.open(InstallDialogComponent, { ...dimensions });
        }
      }
    }
    if (this.jwtService.isLoggedIn) {
      const userLogin = this.jwtService.userData;
      if (userLogin?.role?.entryUri) {
        this.router.navigateByUrl(userLogin.role.entryUri);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  submit(): void {
    if (!this.form.valid) return;

    // Prevent login on staging without a developer account
    if (
      environment.environment.includes('staging') &&
      !environment.developerEmails.includes(this.form.get('email').value)
    ) {
      this.notify.error(
        'This is the development app! Please use a developer account for login or visit the live app instead!',
        10
      );
      return;
    }

    this.loading = true;
    this.backendService
      .post<'auth/login'>('auth/login', {
        body: this.form.value
      })
      .subscribe((d) => {
        this.loading = false;
        this.jwtService.token = d.token;
        this.onLoggedIn();
      });
  }

  onLoggedIn(): void {
    const loginData = this.jwtService.userData;

    if (loginData.role.entryUri) {
      this.router.navigateByUrl(loginData.role.entryUri);
    } else {
      // TODO: hardcoded roles
      if (![6, 10].includes(loginData?.role.id)) this.inspectorService.openTasks$.next(0);

      // If the user attempted to reach a specific route before login
      const attemptedRoute = this.routeService.getAttemptedRoute();
      if (attemptedRoute) {
        this.router.navigateByUrl(attemptedRoute);
        this.routeService.clearAttemptedRoute();
      } else {
        if (loginData.role?.entryUri) {
          this.router.navigate([loginData.role.entryUri]);
        } else {
          this.router.navigate(['/user/dashboard']);
        }
      }
    }
  }
}
