import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'biolib';
import { catchError, filter, map, Subscription, tap, throwError } from 'rxjs';
// import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { environment } from 'src/environments/environment';
import { JwtService } from './jwt.service';
import { NotificationService } from './notification.service';
import { ApiOperation, ApiRequests, ApiResponses, operations, paths } from 'src/app/shared/types/api.type';

export { ApiOperation, ApiResponses, operations as ApiOperations, paths } from 'src/app/shared/types/api.type';

type Endpoint = keyof paths;

type Options<T extends ApiOperation> = {
  query?: operations[T]['parameters']['query'];
  path?: operations[T]['parameters']['path'];
  body?: ApiRequests[T];
  ignoredErrors?: string[];
};

// class RetriableError extends Error {}
// class FatalError extends Error {}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(
    private notificationService: NotificationService,
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {}

  downloadFile<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.get(url, { headers, observe: 'response', responseType: 'blob' }).pipe(
      map((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const name = this.parseContentDisposition(contentDisposition) ?? 'file';
        return { name, content: response.body as Blob };
      }),
      catchError((error) => {
        error.error.text().then((text: string) => {
          (error.error as unknown) = JSON.parse(text);
          this.handleError(error, options.ignoredErrors);
        });

        return throwError(error);
      })
    );
  }

  downloadFilePost<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.post(url, options.body, { headers, observe: 'response', responseType: 'blob' }).pipe(
      map((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const name = this.parseContentDisposition(contentDisposition) ?? 'file';
        return { name, content: response.body as Blob };
      }),
      catchError((error) => {
        error.error.text().then((text: string) => {
          (error.error as unknown) = JSON.parse(text);
          this.handleError(error, options.ignoredErrors);
        });

        return throwError(error);
      })
    );
  }

  openWindow<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options, true);
    window.open(url, '_blank');
  }

  get<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    // console.log(headers);

    return this.http.get<ApiResponses[T]>(url, { headers, observe: 'response' }).pipe(
      tap((response) => this.handleJwtUpdate(response)),
      map((response) => response.body as ApiResponses[T]),
      catchError((error) => this.handleError(error, options.ignoredErrors))
    );
  }

  post<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.post<ApiResponses[T]>(url, options.body, { headers, observe: 'response' }).pipe(
      tap((response) => this.handleJwtUpdate(response)),
      map((response) => response.body as ApiResponses[T]),
      catchError((error) => this.handleError(error, options.ignoredErrors))
    );
  }

  upload<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();
    return this.http
      .post<ApiResponses[T]>(url, options.body, { headers, observe: 'events', reportProgress: true })
      .pipe(
        tap((event) => {
          if (event.type === HttpEventType.Response) {
            this.handleJwtUpdate(event);
          }
        }),
        map((event) => {
          if (event.type === HttpEventType.Response) {
            return event.body as ApiResponses[T];
          }
          return null;
        }),
        filter((response): response is ApiResponses[T] => response !== null),
        catchError((error) => this.handleError(error, options.ignoredErrors))
      );
  }

  put<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.put<ApiResponses[T]>(url, options.body, { headers, observe: 'response' }).pipe(
      tap((response) => this.handleJwtUpdate(response)),
      map((response) => response.body as ApiResponses[T]),
      catchError((error) => this.handleError(error, options.ignoredErrors))
    );
  }

  patch<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.patch<ApiResponses[T]>(url, options.body, { headers, observe: 'response' }).pipe(
      tap((response) => this.handleJwtUpdate(response)),
      map((response) => response.body as ApiResponses[T]),
      catchError((error) => this.handleError(error, options.ignoredErrors))
    );
  }

  delete<T extends ApiOperation>(endpoint: Endpoint, options: Options<T> = {}) {
    const url = this.getUrl(endpoint, options);
    const headers = this.createHeaders();

    return this.http.delete<ApiResponses[T]>(url, { headers, observe: 'response' }).pipe(
      tap((response) => this.handleJwtUpdate(response)),
      map((response) => response.body as ApiResponses[T]),
      catchError((error) => this.handleError(error, options.ignoredErrors))
    );
  }

  // eventSource<T extends ApiOperation>(endpoint: Endpoint, callback: (data: ApiResponses[T]) => void, options: Options<T> = {}) {
  //   const url = this.getUrl(endpoint, options);
  //   const headers = this.createHeaders();

  //   const controller = new AbortController();

  //   // EventSource does not support passing headers, so we use a library instead.
  //   fetchEventSource(url, {
  //     headers: this.headersToObject(headers),
  //     openWhenHidden: true,
  //     async onopen(response) {
  //       if (response.ok && response.headers.get("content-type") === EventStreamContentType) return;

  //       if (response.status == 401) throw new FatalError("Unauthorized");
  //       throw new RetriableError();
  //     },
  //     onmessage: (data) => {
  //       if (!data.data) return;

  //       try {
  //         const parsedData = JSON.parse(data.data) as ApiResponses[T];
  //         callback(parsedData);
  //       }
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       catch (e: any) {
  //         console.error(e.message, data.data);
  //         throw e;
  //       }
  //     },
  //     onerror: (error) => {
  //       console.error(error);
  //       // this.notificationService.error('Event konnte nicht empfangen werden: ' + error);

  //       // Stop reconnecting on fatal errors
  //       if (error instanceof FatalError) throw error;
  //     },
  //     signal: controller.signal
  //   });

  //   return () => controller.abort();
  // }

  private handleError(error: HttpErrorResponse, ignoredErrors: string[] = []) {
    console.log(error);
    if (error.statusText && ignoredErrors.includes(error.statusText)) return throwError(() => error);

    if (error.status === 401 || error.error.statusCode == 401) {
      this.handleInvalidJwtError();
    } else if (error.error.message) {
      this.notificationService.error(error.error.message);
    } else if (error.status === 0) {
      this.notificationService.error('Der Server ist temporär nicht erreichbar. Bitte versuchen Sie es später erneut.');
    } else {
      this.notificationService.error('Ein Fehler ist aufgetreten: ' + error.statusText);
    }

    return throwError(() => error);
  }

  private handleJwtUpdate(response: HttpResponse<unknown>) {
    const newJwt = response.headers.get('Set-JWT');
    if (newJwt) this.jwtService.token = newJwt;
  }

  private getUrl<T extends ApiOperation>(endpoint: string, options: Options<T>, appendToken = false) {
    if (options.path) {
      for (const [k, v] of Object.entries(options.path)) {
        endpoint = endpoint.replace(`{${k}}`, encodeURIComponent(String(v)));
      }
    }

    let url = `${environment.apiUrl}${endpoint}`;
    if (appendToken && !options.query) options.query = {};
    if (options.query) {
      const isValidParam = (value: unknown) => !isEmpty(value) && (typeof value !== 'number' || value > 0);
      const encodeParam = (value: unknown) =>
        Array.isArray(value) ? value.map(encodeURIComponent).join(',') : encodeURIComponent(String(value));
      const params = Object.entries(options.query)
        .filter(([_, v]) => isValidParam(v))
        .map(([k, v]) => `${k}=${encodeParam(v)}`);

      if (appendToken) params.push(`t=${this.jwtService.token}`);
      if (params.length) url += `?${params.join('&')}`;
    }

    return url;
  }

  private createHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    const jwt = this.jwtService.token;
    if (jwt) headers = headers.set('Authorization', `Bearer ${jwt}`);

    return headers;
  }

  private headersToObject(headers: HttpHeaders) {
    const obj: Record<string, string> = {};
    headers.keys().forEach((key) => {
      const value = headers.get(key);
      if (value) obj[key] = value;
    });

    return obj;
  }

  private parseContentDisposition(contentDisposition: string | null) {
    if (!contentDisposition) return null;

    let split = contentDisposition.split(';');
    if (split.length < 2) return null;

    split = split[1].trim().split('=');
    if (split.length < 2) return null;

    return split[1].replaceAll('"', '');
  }

  private handleInvalidJwtError() {
    this.notificationService.error('Ihr Login ist abgelaufen. Bitte loggen Sie sich erneut ein.');
    this.router.navigateByUrl('/auth/login');
  }
}

export type BackendResult = {
  success?: boolean;
  message: string;
  statusCode?: number;
  error: string;
  details: Record<string, unknown>;
  errorCode: string;
};

export function subscriptionIsActive(subscription?: Subscription): boolean {
  return subscription && !subscription.closed;
}

// TODO: Remove this, error format is different now
export interface IResponseInterface<T> {
  state: boolean;
  data: T;
  error?: any;
}
