import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly backend: BackendService,
    private readonly notify: NotificationService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appVersion = localStorage.getItem('app_version') || '1.0.0';

    // Clone the request and add the version header, only if request url is our own backend
    let requ = req.clone({
      setHeaders: {
        'incoming-version': appVersion
      }
    });
    // otehrwise just use the inital request
    if (!req.url.includes(environment.apiUrl)) {
      requ = req.clone();
    }

    return next.handle(requ).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((e) => {
        if (e.type === HttpEventType.Response && e.headers.get('version')) {
          const cachedVersion = localStorage.getItem('app_version');
          const apiVersion = e.headers.get('version');
          if (!cachedVersion) {
            localStorage.setItem('app_version', apiVersion);
            this.backend.showReloadButton$.next(true);
            return;
          }
          try {
            const cachedParts = cachedVersion.split('.').map(Number);
            const apiParts = apiVersion.split('.').map(Number);

            for (let i = 0; i < cachedParts.length; i++) {
              if (cachedParts[i] > apiParts[i]) break;
              if (cachedParts[i] < apiParts[i]) {
                localStorage.setItem('app_version', apiVersion);
                this.backend.showReloadButton$.next(true);
              }
            }
          } catch (error) {
            this.notify.error('Versioning error, contact support!', error);
          }
        }
      })
    );
  }
}
