import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true
})
export class ObjectKeysPipe implements PipeTransform {
  transform(value: any): any {
    return Object.keys(value);
  }
}
