<!-- If we are in data.steps[0].process?.phase === 'Ordering'
    We do not want an close button on the actions part, we have a close button on the top right corner
    Else the back button and the Bestellung abschicken button will not fit in the actions bar
-->
<ng-container *transloco="let t">
  <h1 mat-dialog-title *ngIf="data.steps.length">
    <div class="flex align-center space-between">
      <div class="flex align-center">
        <mat-icon class="material-symbols-outlined">assignment_add</mat-icon>
        {{ data.steps[0].process?.title }}
      </div>
      <button
        mat-icon-button
        *ngIf="data.steps[0].process?.phase === 'Ordering'"
        [mat-dialog-close]="{ actionPerformed }"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="data.steps[0].process?.phase === 'Defect'; else standard">
      <small *ngIf="data.steps[0].process?.id_code_1">
        {{ data.steps[0].process?.id_code_2 }}
      </small>
    </ng-container>
    <ng-template *ngIf="data.steps[0].process?.phase !== 'Ordering'" #standard>
      <small *ngIf="data.steps[0].process?.id_code_1">
        {{ data.steps[0].process?.id_code_1 }} <br />
        {{ t('process.date_of_visit') }}:
        {{ data.steps[0].process?.id_code_2 | date: 'longDate' : '+0200' : 'de' }}
      </small>
    </ng-template>
  </h1>
  <div mat-dialog-content>
    <mat-progress-bar
      class="space-xs-bottom margin-xs-bottom"
      color="primary"
      mode="determinate"
      [value]="progress"
    ></mat-progress-bar>

    <app-process-handler
      [steps]="steps"
      (allStepsPerformed)="onAllStepsPerformed($event)"
      (onActionPerformed)="onActionPerformed()"
      [initialProcessStartOptions]="data.processStartOptions"
      [noTabs]="data.noTabs"
      [translations]="data.translations"
      [showDescription]="showDescriptionStepTitle"
    ></app-process-handler>
  </div>
  <mat-dialog-actions class="gap-xs flex space-between justify-end">
    <button *ngIf="data.steps[0].process?.phase !== 'Ordering'" [mat-dialog-close]="{ actionPerformed }" mat-button>
      {{ t('general.close') }}
    </button>
    <div>
      <button *ngIf="backButton" mat-raised-button (click)="back()">
        {{ t('go-back') }}
      </button>
      <button *ngIf="proceedButton" mat-raised-button color="primary" (click)="proceed()">
        <ng-container *ngIf="lastStepSingleStepProcess; else proceedButton">
          {{ t('general.perform') }}
        </ng-container>
        <ng-template #proceedButton>
          <ng-container *ngIf="lastStep; else elseBlock">{{ t('general.finish') }}</ng-container>
          <ng-template #elseBlock> {{ t('general.forward') }} </ng-template>
        </ng-template>
      </button>
      <ng-container *ngIf="choices">
        <button *ngFor="let choice of choices" mat-raised-button color="primary" (click)="choiceSelected(choice.title)">
          {{ t(choice.title) }}
        </button>
      </ng-container>
    </div>
  </mat-dialog-actions>
</ng-container>
