import { Component, ContentChild, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractStepHandleComponent } from '../abstract-step-handle/abstract-step-handle.component';
import { from, Observable, Subscription } from 'rxjs';
import { ProcessHandlerService } from '../../process-handler-dialog/process-handler.service';
import { ManualInput, InputDefinition, SelectOption } from 'src/app/interfaces/process/step-definition/manual-input.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { EquipmentModel } from 'src/app/models/equipment.model';
import { EquipmentService } from 'src/app/services/equipment.service';
import { config } from 'src/config';
import { MatDialog } from '@angular/material/dialog';
import { CameraDialogComponent } from 'src/app/components/dialogs/camera-dialog/camera-dialog.component';
import { CameraService } from 'src/app/services/camera.service';
import { DatePipe, NgClass } from '@angular/common';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { SelectOnMapDialogComponent } from 'src/app/components/dialogs/select-on-map/select-on-map-dialog.component';
import { DeviceDialogSize } from '../../process-handler-dialog/interfaces/device-dialog-size.interface';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { GoogleMapService } from 'src/app/services/map-google.service';
import { ConfirmAddressComponent } from 'src/app/components/dialogs/confirm-google-address/confirm-address-dialog.component';
import { ProcessService } from 'src/app/services/process.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { SearchSelectComponent } from '../../../../components/search-select/search-select.component';
import { MtxDatetimepicker, MtxDatetimepickerInput, MtxDatetimepickerToggle } from '@ng-matero/extensions/datetimepicker';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { BackendService } from 'src/app/services/backend.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-step-handle-manual-input',
  templateUrl: './step-handle-manual-input.component.html',
  styleUrls: ['./step-handle-manual-input.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatError,
    MatButton,
    MtxDatetimepicker,
    MtxDatetimepickerInput,
    MtxDatetimepickerToggle,
    MatSuffix,
    SearchSelectComponent,
    MatSlider,
    MatSliderThumb,
    MatRadioGroup,
    MatRadioButton,
    MatCheckbox,
    MatIcon,
    MatSelect,
    MatOption,
    MatIconButton,
    MatSlideToggleModule
  ]
})
export class StepHandleManualInputComponent extends AbstractStepHandleComponent implements OnInit, OnChanges, OnDestroy {
  @ContentChild('overlayContent') overlayContent: ElementRef;
  @Input() step: Step<ManualInput>;

  maxDate = DateTime.now().toISO();

  readonly inputTypes = INPUT_TYPES;

  showInfo = true;
  siteReports: Step[] = [];
  itemsInCart: EquipmentModel[];
  backendUrl = config.apiUrl;

  backSubscription: Subscription;
  proceedSubscription: Subscription;
  streetNumber: number;
  defectArticles: { id: string; file?: File; webCamData?: string }[] = [];
  missingArticles: string[] = [];
  notFittingArticles: string[] = [];
  notDeliveredArticles: string[] = [];
  webCamData: string;
  filePreviewPath: string;
  isMobile: boolean;
  actionHandled: boolean;
  dimensions: DeviceDialogSize;

  forbiddenSubstrings = ['Bunker', 'Bunkerstation', 'Slurink', 'Reinplus', 'Heijmen', 'Büro'];
  userInputAddress: string[];

  constructor(
    private readonly backendService: BackendService,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly notify: NotificationService,
    private readonly equipmentService: EquipmentService,
    private readonly dialog: MatDialog,
    private readonly cameraService: CameraService,
    private readonly datePipe: DatePipe,
    private readonly translate: TranslocoService,
    private readonly googleService: GoogleMapService,
    private readonly processService: ProcessService,
    mobileService: MobileViewService
  ) {
    super();
    this.isMobile = mobileService.detectMobileDevice();

    this.dimensions = processHandlerService.getDialogScreenSize();
  }

  ngOnInit(): void {
    this.proceedSubscription = this.processHandlerService.proceedClicked.subscribe(() => {
      this.onApplyClicked();
    });

    this.backSubscription = this.processHandlerService.backClicked.subscribe(() => this.onBackClicked());
  }

  ngOnDestroy(): void {
    // if step is confirm lsr return to step before, otherwise the cart could not be edited properly
    if (!this.actionHandled && this.step.step_definition_id === 298) {
      this.onBackClicked();
    }

    this.backSubscription.unsubscribe();
    this.proceedSubscription.unsubscribe();
  }

  computePath(item: EquipmentModel): string {
    return this.equipmentService.computePath(item);
  }

  shouldShowOverlay(): boolean {
    return this.overlayContent?.nativeElement.children.length > 0;
  }

  initialize() {
    const typeData = this.step.stepDefinition.type_data;
    for (const inputDefinition of typeData.input_definition_ids) {
      if (
        ['select', 'radio', 'date-time', 'checkboxArray', 'slider', 'info', 'hidden', 'lsr-image', 'street', 'onmap', 'manual'].includes(
          inputDefinition.options?.suggestedInputMethod
        )
      ) {
        const input = typeData.inputs.find((i) => i.id === inputDefinition.dataField);
        if (input) {
          input.value = Number(input.value) || input.value;
          input.inputDefinitionOfStep = inputDefinition;
          this.getSelectOptions(inputDefinition).subscribe((o) => {
            input._selectOptions = o;
          });
        }
      }

      if (inputDefinition.options?.suggestedInputMethod === 'street') {
        const input = typeData.inputs.find((i) => i.id === inputDefinition.dataField);
        if (input.value) {
          if (Number(input.value)) {
            this.streetNumber = input.value;
            input.value = null;
          } else {
            const parts = input.value.split(' ');
            if (parts.length > 1) this.streetNumber = parts.pop();
            input.value = parts.toString().replace(/,/g, ' ');
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.step) {
      if (this.step.process.process_definition_id === 19 && this.step.process.DataFields) {
        const supplierIds = this.step.process.DataFields.find((field) => field.name === 'Artikelnummern').value;
        this.backendService
          .get<'equipment/getAllEquipment'>('equipment/all', {
            query: {
              supplierNumbers: supplierIds
            }
          })
          .subscribe((res) => {
            this.itemsInCart = res as any; // TODO type
          });
      }

      this.initialize();
      const back = this.step.stepDefinition.type_data.terminate_buttons?.find((e) => e.label.includes('back'));
      let lastStep: boolean;
      let lastStepSingleStepProcess: boolean;
      if (this.processHandlerService.isSingleStepVisit(this.step)) {
        lastStepSingleStepProcess = true;
      } else if (
        (this.processHandlerService
          .getProcessStepsOrder(this.step.process.process_definition_id)
          .pop() ?? [])
          .find((s) => s === this.step.step_definition_id)
      ) {
        lastStep = true;
      }
      this.processHandlerService.stepButtons.next({
        proceed: true,
        back: Boolean(back),
        lastStep,
        lastStepSingleStepProcess
      });
    }
  }

  setNow(input) {
    input.value = new Date();
  }

  onBackClicked(): void {
    const backButton = this.step.stepDefinition.type_data.terminate_buttons.find((e) => e.label.includes('back'));
    this.actionHandled = true;
    this.performBackAction.emit({
      stepId: this.step.id,
      stepDefinitionId: backButton.step_definition_id
    });
  }

  openMapDialog(input) {
    const dialogRef = this.dialog.open<SelectOnMapDialogComponent>(SelectOnMapDialogComponent, {
      ...this.dimensions,
      data: { position: JSON.parse(input.value) }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.mapPosition) {
        input.value = JSON.stringify(data.mapPosition);
      }
    });
  }

  onApplyClicked(): void {
    if (this.step.step_definition_id === 272) {
      const defects = this.step.stepDefinition.type_data.inputs.find((i) => i.name === 'defectArticles');
      const missings = this.step.stepDefinition.type_data.inputs.find((i) => i.name === 'missingArticles');
      const notFittings = this.step.stepDefinition.type_data.inputs.find((i) => i.name === 'notFittingArticles');
      const notDelivered = this.step.stepDefinition.type_data.inputs.find((i) => i.name === 'notYetDelivered');
      // add array for picture paths and ids
      // extend arrays
      let stringArray = missings.value as string[];
      missings.value = (stringArray || []).concat(this.missingArticles);

      stringArray = notFittings.value as string[];
      if (!stringArray) {
        stringArray = [];
      }

      if (!stringArray.length && !this.notFittingArticles.length) {
        notFittings.value = 0;
      } else {
        notFittings.value = (stringArray || []).concat(this.notFittingArticles);
      }

      notDelivered.value = this.notDeliveredArticles;

      // upload all images for defects if any
      if (this.defectArticles.length) {
        // check if any defectArticle has no file
        if (this.defectArticles.some((defect) => !defect.file)) {
          this.notify.error(`Missing Images for defect articles! Please choose a image for all defect articles.`);
          return;
        }

        // upload all at once, return the paths to store in datafield. Then emit action handled
        const formData = new FormData();
        formData.append('processId', this.step.process.id.toString());

        // Append files
        this.defectArticles.forEach((defect) => formData.append('files', defect.file));

        // Append IDs
        this.defectArticles.forEach((defect) => formData.append('ids', defect.id));

        this.backendService
          .post<'equipment/uploadImages'>('equipment/upload-images', {
            body: formData as any // TODO: FormData is not supported by the backend service
          })
          .subscribe(() => {
            this.handleAction();
          });
      } else {
        this.handleAction();
      }
    } else {
      this.handleAction();
    }
  }

  onCameraClick(image?: string, item?: EquipmentModel, input?) {
    const dialogRef = this.dialog.open<CameraDialogComponent>(CameraDialogComponent, {
      ...this.dimensions,
      data: {
        image
      }
    });

    dialogRef.afterClosed().subscribe((webcamData) => {
      const currDate = new Date();
      const sixMonthsFromNow = new Date(currDate);
      sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
      if (webcamData) {
        this.webCamData = webcamData;
        const file = this.cameraService.dataURLtoFile(webcamData, `img_${this.datePipe.transform(currDate, 'y-LL-dd_HH:mm:ss')}`);
        this.fileChange(file, item, webcamData, input);
      }
    });
  }

  fileChange(file: File, item?: EquipmentModel, webCamData?: string, input?): void {
    // extend the defect articles array
    if (item) {
      const article = this.defectArticles.find((e) => e.id === item.supplierNumber);
      article.file = file;
      if (webCamData) {
        article.webCamData = webCamData;
      }
    } else {
      this.filePreviewPath = file.name;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      input.value = dataTransfer.files;
    }
  }

  getInputOptions(inputId: number): any {
    return this.step.stepDefinition.type_data.input_definition_ids.find((i) => i.dataField === inputId)?.options;
  }

  getFileNamePreview(item: EquipmentModel) {
    return this.defectArticles.find((e) => e.id === item.supplierNumber)?.file?.name;
  }

  getWebCamData(item?: EquipmentModel) {
    if (item) {
      return this.defectArticles.find((e) => e.id === item.supplierNumber)?.webCamData;
    } else {
      return this.webCamData;
    }
  }

  /**
   * Defect articles array contains the supplier id, and image data (name, type, path)
   *
   * @param checked
   * @param item
   */
  toggleDefect(checked: boolean, item: string) {
    if (!checked) {
      // reduce
      const index = this.defectArticles.findIndex((e) => e.id === item);
      if (index > -1) {
        this.defectArticles.splice(index, 1);
      }
    } else {
      // extend
      this.defectArticles.push({ id: item });
    }
  }

  toggleMissing(checked: boolean, item: string) {
    if (!checked) {
      // reduce
      const index = this.missingArticles.findIndex((e) => e === item);
      if (index > -1) {
        this.missingArticles.splice(index, 1);
      }
    } else {
      // extend
      this.missingArticles.push(item);
    }
  }

  toggleFitting(checked: boolean, item: string) {
    if (!checked) {
      // reduce
      const index = this.notFittingArticles.findIndex((e) => e === item);
      if (index > -1) {
        this.notFittingArticles.splice(index, 1);
      }
    } else {
      // extend
      this.notFittingArticles.push(item);
    }
  }

  toggleNotDelivered(checked: boolean, item: string) {
    if (!checked) {
      // reduce
      const index = this.notDeliveredArticles.findIndex((e) => e === item);
      if (index > -1) {
        this.notDeliveredArticles.splice(index, 1);
      }
    } else {
      // extend
      this.notDeliveredArticles.push(item);
    }
  }

  updateAddress(value) {
    const tranfsormedValue = JSON.parse(value.replace(/'/g, '"'));
    // modify step inputs
    this.step.stepDefinition.type_data.inputs.find((i) => i.id === 215).value = tranfsormedValue.street;
    this.streetNumber = tranfsormedValue.number;
    this.step.stepDefinition.type_data.inputs.find((i) => i.id === 216).value = tranfsormedValue.postal;
    this.step.stepDefinition.type_data.inputs.find((i) => i.id === 217).value = tranfsormedValue.city;
    this.step.stepDefinition.type_data.inputs.find((i) => i.id === 218).value = tranfsormedValue.country;
  }

  private getSelectOptions(inputDefinition: InputDefinition): Observable<SelectOption[]> {
    let request: Observable<SelectOption[]>;
    if (inputDefinition.options.selectOptionsUrl) {
      // TODO: new backend
      switch (inputDefinition.options.selectOptionsUrlMethod) {
        case 'POST':
          request = this.backendService.post<any>(inputDefinition.options.selectOptionsUrl.substring(1) as any, {});
          break;
        default:
          request = this.backendService.get<any>(inputDefinition.options.selectOptionsUrl.substring(1) as any);
          break;
      }
      return new Observable<SelectOption[]>((observer) => {
        request.subscribe((res) => {
          console.log(inputDefinition, res)
          if (inputDefinition.options.map) {
            observer.next(res.map((r) => ({ label: r[inputDefinition.options.map.label], value: r[inputDefinition.options.map.value] })));
          }
          else {
            observer.next(res);
          }
          observer.complete();
        });
      });
    }
    if (inputDefinition.options.selectOptions) {
      return from([inputDefinition.options.selectOptions]);
    }
    return new Observable<SelectOption[]>((observer) => {
      observer.next([]);
      observer.complete();
    });
  }

  private handleAction() {
    const inputData: any = {};

    const missingInputs: string[] = [];
    let notificationBunker = false;
    let validateAddress: boolean;
    // check for step definition ids 246 and 327, no bunker delivery
    for (const input of this.step.stepDefinition.type_data.inputs) {
      if (this.step.step_definition_id === 246 || this.step.step_definition_id === 327) {
        for (const substring of this.forbiddenSubstrings) {
          if (input.value && input.value.toString().toLocaleLowerCase().includes(substring.toLocaleLowerCase())) {
            input.value = '';
            this.notify.error(this.translate.translate('views.psa.order.bunker_forbidden'));
            notificationBunker = true;
            break;
          }
        }
      }

      if (!notificationBunker) {
        if (input.type === INPUT_TYPES.DATE && input.value) {
          // all dates are saved as utc in DB if we now go back to a step with a Date and save it again it will be subtracted by our local timezone offset
          // therefore we need to add the offset to not change the date
          const timeZoneOffset = new Date().getTimezoneOffset();
          const initialDate = new Date(input.value);

          if (input.inputDefinitionOfStep?.options.suggestedInputMethod !== 'date-time') {
            initialDate.setHours(Math.abs(timeZoneOffset / 60));
            initialDate.setMinutes(1);
          }
          input.value = initialDate.toUTCString();
        }
        if (input.value !== null && input.value !== '') {
          // concatenate the street and address
          if (input.inputDefinitionOfStep?.options?.suggestedInputMethod === 'street') {
            // its a address, so validate the address and show recommendation if errors occur
            // toDo: Make it conditional to validate, not only by using suggestedInputMethod === street
            validateAddress = true;
            if (!this.streetNumber) missingInputs.push('Nr.');
            inputData[input.id] = `${input.value} ${this.streetNumber}`;
          } else {
            inputData[input.id] = input.value;
          }
        } else {
          if (input.required) {
            missingInputs.push(input.name);
            if (!this.streetNumber && !missingInputs.includes('Nr.') && this.step.step_definition_id === 246) {
              missingInputs.push('Nr.');
            }
          } else if (input.type === this.inputTypes.YES_NO) {
            inputData[input.id] = false;
          }
        }
      }
    }

    // ToDo: put into hook!!
    if (this.step.step_definition_id === 325) {
      this.processService.updateCreateDataFieldValue(290, this.step.process_id, 'Bunkerstation').subscribe((df) => console.log(df));
    }
    if (this.step.step_definition_id === 327) {
      this.processService.updateCreateDataFieldValue(290, this.step.process_id, 'Büro').subscribe((df) => console.log(df));
      this.processService.updateCreateDataFieldValue(291, this.step.process_id, 'GEFO').subscribe((df) => console.log(df));
    }

    if (!notificationBunker) {
      if (!missingInputs.length) {
        if (validateAddress) {
          // we are only using suggestedINputmethod= street in one step. Thats why the df ids are hardcoded -> not goog, change if neccessarry.
          // validate every address with the validation api from google: https://developers.google.com/maps/documentation/address-validation?hl=de
          // save for checking if user eventually changed something before sending again to google
          // ToDo: use types for api variables
          let canValidateWithAPI = true;
          if (this.userInputAddress) {
            if (
              inputData[215] === this.userInputAddress[0] &&
              inputData[216] === this.userInputAddress[1] &&
              inputData[217] === this.userInputAddress[2] &&
              inputData[218] === this.userInputAddress[3]
            ) {
              // nothing changed, dont send another request
              canValidateWithAPI = false;
            }
          }
          if (canValidateWithAPI) {
            this.googleService.invalidateAddressCache('recommendation');
          }
          this.userInputAddress = [inputData[215], inputData[216], inputData[217], inputData[218]];
          this.googleService
            .validateAddress('recommendation', {
              streetNr: inputData[215],
              postal: inputData[216],
              city: inputData[217],
              country: inputData[218]
            })
            .subscribe((res) => {
              const verdict = res.result.verdict;
              const address = res.result.address;
              if (
                ['PREMISE', 'SUB_PREMISE', 'PREMISE_PROXIMITY'].includes(verdict.validationGranularity) &&
                verdict.addressComplete &&
                (verdict.hasInferredComponents || verdict.hasReplacedComponents)
              ) {
                // show recommendation
                const dialogRef = this.dialog.open<ConfirmAddressComponent>(ConfirmAddressComponent, {
                  ...this.dimensions,
                  data: {
                    addressComponents: address.addressComponents,
                    initialAddress: `${inputData[215]}, ${inputData[216]} ${inputData[217]}, ${inputData[218]}`
                  }
                });

                dialogRef.afterClosed().subscribe((res) => {
                  if (res) {
                    this.addressValidationComplete(inputData, address, true);
                  }
                });
              } else if (
                ['PREMISE', 'SUB_PREMISE', 'PREMISE_PROXIMITY'].includes(verdict.validationGranularity) &&
                verdict.addressComplete &&
                !verdict.hasInferredComponents &&
                !verdict.hasReplacedComponents &&
                !verdict.hasUnconfirmedComponents
              ) {
                // move on, everything is okay
                this.addressValidationComplete(inputData, address);
              } else {
                //  handle everything else, it should only be if a address has unconfirmedComponents
                try {
                  // address cannot be confirmed, show dialog to user if we can proceed.
                  const dialogRef = this.dialog.open<ConfirmAddressComponent>(ConfirmAddressComponent, {
                    ...this.dimensions,
                    data: {
                      addressComponents: address.addressComponents,
                      confirmation: true,
                      initialAddress: `${inputData[215]}, ${inputData[216]} ${inputData[217]}, ${inputData[218]}`
                    }
                  });

                  dialogRef.afterClosed().subscribe((res) => {
                    if (res) {
                      this.addressValidationComplete(inputData, address, true);
                    }
                  });
                } catch (error) {
                  this.notify.error('An unspecified error occured, please check your address. If you cannot solve the problem, contact support.');
                }
              }
            });
        } else {
          this.actionHandled = true;
          this.stepActionHandled.emit({ input: inputData });
        }
      } else {
        this.notify.error(`Missing Inputs! ${missingInputs.toString()}`);
      }
    }
  }

  private addressValidationComplete(inputData: any, address: any, showDialog?: boolean) {
    if (address.postalAddress.addressLines.length > 1) {
      inputData[215] = `${address.postalAddress.addressLines.toString()}`;
    } else {
      inputData[215] = `${address.formattedAddress.split(',')[0]}`;
    }
    inputData[216] = `${address.postalAddress.postalCode}`;
    inputData[217] = `${address.postalAddress.locality}`;
    inputData[218] = `${address.postalAddress.regionCode}`;
    this.actionHandled = true;
    this.stepActionHandled.emit({ input: inputData });
    if (showDialog)
      this.notify.success(
        `${this.translate.translate('google.address.success')}: ${address.postalAddress.addressLines.toString()}, ${address.postalAddress.postalCode} ${address.postalAddress.locality}, ${address.postalAddress.regionCode}`
      );
  }
}

export const INPUT_TYPES = {
  DATE: 4,
  YES_NO: 5,
  TEXT: 6,
  LINK: 7,
  ARRAY_STRING: 8,
  FILE: 9,
  TEXT_READONLY: 10
};
