import { Injectable } from '@angular/core';
import { CombinedSizes, JACKET_SIZE_HAVEP, JACKET_SIZE_MEN, JacketSize } from '../interfaces/user/clothing-sizes';
import { config } from 'src/config';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private backendUrl = config.apiUrl;

  computePath(item: any): string { // TODO: type
    if (item.path) {
      if (item.path.startsWith('http://')) {
        return item.path;
      } else {
        return `${this.backendUrl}equipment/${item.id}/image`;
      }
    } else {
      return 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg';
    }
  }

  /** This function calculates the distances from the midpoint of each range and selects the size with the smallest total distance. */
  getClothingSizes(height: number, chest: number, taille: number, hip: number, inseam?: number): CombinedSizes {
    let closestSizeBody: string | undefined;
    let closestSizeMultinorm: string | undefined;
    let closestDistanceBody: number = Number.MAX_SAFE_INTEGER;

    JACKET_SIZE_MEN.forEach((sizeInfo: JacketSize) => {
      const heightRange = sizeInfo.height.split('-').map(Number);
      const chestRange = sizeInfo.chest.split('-').map(Number);
      const tailleRange = sizeInfo.taille.split('-').map(Number);

      const heightDistance = Math.abs(height - (heightRange[0] + heightRange[1]) / 2);
      const chestDistance = Math.abs(chest - (chestRange[0] + chestRange[1]) / 2);
      const tailleDistance = Math.abs(taille - (tailleRange[0] + tailleRange[1]) / 2);

      const totalDistance = heightDistance + chestDistance + tailleDistance;

      if (totalDistance < closestDistanceBody) {
        closestSizeBody = sizeInfo.size;
        closestDistanceBody = totalDistance;
      }
    });

    let minSizeByChest = '44';
    let minSizeByTaille = '44';
    let minSizeByInseam = '44';

    // Find minimum required size for each measurement
    JACKET_SIZE_HAVEP.forEach((sizeInfo: JacketSize) => {
      if (chest >= Number(sizeInfo.chest)) minSizeByChest = sizeInfo.size;
      if (taille >= Number(sizeInfo.taille)) minSizeByTaille = sizeInfo.size;
      if (inseam >= Number(sizeInfo.inseam)) minSizeByInseam = sizeInfo.size;
    });

    // Take the largest of the three sizes to ensure all measurements fit
    closestSizeMultinorm = Math.max(
      Number(minSizeByChest),
      Number(minSizeByTaille),
      Number(minSizeByInseam)
    ).toString();

    return { bodywear: closestSizeBody, eur: closestSizeMultinorm };
  }
}
