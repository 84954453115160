import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../services/backend.service';

@Pipe({
  name: 'idToName',
  standalone: true
})
export class IdToNamePipe implements PipeTransform {
  constructor(private backendService: BackendService) {}

  transform(userId: any, ...args: unknown[]): Observable<string> {
    return new Observable<string>((observer) => {
      if (Number(userId)) {
        this.backendService.get<'user/getUserNames'>('user/names', {
          query: {
            ids: [userId]
          }
        })
        .subscribe((users) => {
          if (users.length) {
            observer.next(users[0].name);
          }
          else {
            observer.next('-');
          }
          observer.complete();
        });
      } else {
        if (!userId) userId = '-';
        observer.next(userId);
      }
    });
  }
}
