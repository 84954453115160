import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExpansionTableData, ExpansionTableRows } from '../components/expansion-table/interfaces/expansion-table-data.interface';
import { AssetAttributeValue } from '../interfaces/assets/asset-attribute-value.interface';
import { ActionResult } from '../interfaces/process/action-result.interface';
import { DataFieldTranslation } from '../interfaces/process/data-field.interface';
import { IProcessStartOptions } from '../interfaces/process/process.interface';
import { StepDefinition } from '../interfaces/process/step-definition.interface';
import { Step } from '../interfaces/process/step.interface';
import { INPUT_TYPES } from '../process-engine/process/process-handler/step-handle-manual-input/step-handle-manual-input.component';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  public static readonly TYPES = {
    MANUAL_SELECTION: 1,
    MANUAL_INPUT: 2,
    UPLOAD: 3,
    INPUT_WATCHER: 4,
    EMAIL_SENDER: 100,
    WEBHOOK: 101,
    BRANCH_COLLECTOR: 1000
  };

  constructor(
    private readonly backendService: BackendService,
    private readonly datePipe: DatePipe
  ) {}

  static stepDefinitionToFakeStep(
    stepDefinition: StepDefinition,
    id_code_1?: string,
    id_code_2?: string
  ) {
    return {
      stepDefinition,
      step_definition_id: stepDefinition.id,
      title: stepDefinition.title,
      process: {
        id_code_1,
        id_code_2,
        ProcessDefinition: stepDefinition.processDefinition,
        process_definition_id: stepDefinition.process_definition_id,
        title: stepDefinition.processDefinition.title,
        phase: stepDefinition.processDefinition.phase
      }
    };
  }

  getPhaseExecutedProcesses(
    phase: string,
    processDefinitionId: number,
    siteId: number,
    limit: number = 10,
    offset: number = 0
  ) {
    return this.backendService.get<'processEngineDefinition/getPhaseExecutedProcesses'>('process-engine/processDefinition/{id}/getPhaseExecutedProcesses', {
      path: { id: processDefinitionId },
      query: { phase, responsibleCompany: siteId, limit, offset }
    });
  }

  deleteProcess(id: number) {
    return this.backendService.delete<'processEngineProcess/deleteProcess'>('process-engine/process/{id}', {
      path: { id },
    });
  }

  performStep(step: Step, data: any, processOptions?: IProcessStartOptions): Observable<ActionResult> {
    if (Object.values(data.input || {}).find((d: any) => d instanceof FileList)) {
      data = this.convertDataToFormData(data, processOptions);
    } else {
      data = { data };
    }
    if (!step.process_id) {
      // ToDo: this is not a good idea, try to do it safer! Better typing for stepDefinition?
      if (step.process.phase.includes('Reports') || step.process.phase.includes('Visits')) {
        let date: string;
        if (step.stepDefinition.type_data.inputs?.find((e) => e.type === INPUT_TYPES.DATE)) {
          try {
            date = this.datePipe.transform(step.stepDefinition.type_data.inputs[2].value, 'yyyy-MM-dd');
          } catch (error) {
            console.error(error.message);
            return;
          }
        } else {
          date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }
        processOptions.predefined_attributes.id_code_2 = date;
      }

      return this.backendService.post<'processEngineDefinition/startByManualStep'>('process-engine/processDefinition/{processDefinitionId}/startByManualStep/{stepDefinitionId}', {
        path: {
          processDefinitionId: step.process.process_definition_id,
          stepDefinitionId: step.step_definition_id
        },
        body: {
          data: data.data,
          processOptions: processOptions as any // TODO: type
        }
      }) as any; // TODO: type
    }

    return this.backendService.post<'processEngineProcess/performStep'>('process-engine/process/{processId}/step/{stepId}/perform', {
      path: {
        processId: step.process_id,
        stepId: step.id
      },
      body: data
    }) as unknown as Observable<ActionResult>; // TODO: type
  }

  getMultiData(processIds: number[]) {
    return this.backendService.post<'processEngineProcess/getMultiProcessData'>('process-engine/process/getMultiProcessData', {
      body: { processIds }
    });
  }

  updateCreateDataFieldValue(dataFieldDefinitionId: number, processId: number, datafieldValue: any) {
    return this.backendService.post<'processEngineProcess/setDataField'>('process-engine/process/{id}/setDataField', {
      path: {
        id: processId
      },
      body: {
        dataFieldDefinitionId,
        value: datafieldValue
      }
    });
  }

  terminateToOtherStepDefinition(stepId: number, stepDefinitionId: number) {
    return this.backendService.post<'processEngineDefinition/terminateToOtherStepDefinition'>('process-engine/processDefinition/terminateToOtherStepDefinition', {
      body: {
        currentStepId: stepId,
        stepDefinitionId
      }
    })
  }

  getVesselPhone(assetAttributeValues: AssetAttributeValue[]): string {
    return assetAttributeValues.find((e) => e.assetAttribute.name.includes('Telefonnummer'))?.value;
  }

  getVesselEmail(assetAttributeValues: AssetAttributeValue[]): string {
    return assetAttributeValues.find((e) => e.assetAttribute.name.includes('E-Mail'))?.value;
  }

  /**
   * We convert table data to expansiontableData for mobile view.
   *
   * Since we use the DataField 'Visitor' here and need to find it by name reference. We need to use the translations. Because the field is translated into different languages.
   *
   * @param steps
   * @param processPhase
   * @param translations currently only for DF Visitor
   * @returns
   */
  async convertToExpansionTableData(
    steps: Step[],
    processPhase: string,
    translations: DataFieldTranslation[]
  ): Promise<ExpansionTableData[]> {
    const allUsers = await firstValueFrom(this.backendService.get<'user/listUsers'>('user/all'));
    const data = steps?.map((e) => {
      let userId: number;
      let rows: ExpansionTableRows[];
      if (processPhase === 'visits') {
        rows = [
          { header: 'Visit', content: e.process.title },
          { header: 'Schiff', content: e.process.id_code_1 },
          { header: 'Date of Visit', content: this.datePipe.transform(e.process.id_code_2, 'longDate', '+0200', 'de') },
          { header: 'Schritt', content: e.title },
          { header: 'ausgeführt am', content: this.datePipe.transform(e.invoked, 'longDate', '+0200', 'de') }
        ];
        let userName: string;
        // Translate userId to username if needed. We changed from setting the user name in the visitor dataField to setting the userId.
        // Thats why we need the conversion here for the "old" processes.
        const rawVisitorValue = e.process.DataFields.find(
          (field) => field.name === translations.find((trans) => trans.name === 'Visitor').value
        )?.value;
        if (Number(rawVisitorValue)) {
          const user = allUsers.result.find((u) => u.id === Number(rawVisitorValue));
          userId = Number(rawVisitorValue);
          userName = `${user.firstName} ${user.lastName}`;
        } else {
          userName = e.process.DataFields.find(
            (field) => field.name === translations.find((trans) => trans.name === 'Visitor').value
          )?.value;
          const userModel = allUsers.result.find(
            (user) => userName?.includes(user.firstName) && userName?.includes(user.lastName)
          );
          userId = userModel?.id;
        }
        rows.push({
          header: 'ausgeführt von',
          content: userName
        });
      } else if (processPhase === 'emergency') {
        userId = allUsers.result.find((u) => u.id === Number(e.process.responsible_company))?.id;
        rows = [{ header: 'Process', content: e.process.title }];
      }

      return {
        title: e.process.title || 'Emergency Report',
        description: this.datePipe.transform(e.process.id_code_2, 'longDate', '+0200', 'de'),
        rows,
        processId: e.process.id,
        canResume: e.canResume,
        userId,
        stepId: e.id
      } as ExpansionTableData;
    });
    return data;
  }

  private convertDataToFormData(data: any, processOptions?: any): FormData {
    const formData: FormData = new FormData();
    const postData: any = {};
    for (const id in data.input || []) {
      if (data.input.hasOwnProperty(id)) {
        if (data.input[id] instanceof FileList) {
          for (const file of data.input[id]) {
            formData.append(id, file);
          }
          delete data.input[id];
        } else {
          postData[id] = data.input[id];
        }
      }
    }
    formData.append('data', JSON.stringify(data));
    formData.append('processOptions', JSON.stringify(processOptions));
    return formData;
  }
}
