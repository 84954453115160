<h1 mat-dialog-title>{{ data?.title }}</h1>
<div mat-dialog-content style="min-width: 300px" [innerHTML]="data?.body"></div>
<div mat-dialog-actions style="float: right" *transloco="let t">
  <button mat-button (click)="onClick(null)">{{ t('general.cancel') }}</button>
  <button
    *ngIf="!data.deleteType && !data.hideYes"
    mat-raised-button
    (click)="onClick(true)"
    cdkFocusInitial
    color="primary"
  >
    {{ t('general.yes') }}
  </button>
  <button *ngIf="data.deleteType" mat-raised-button color="warn" (click)="onClick(true)">
    {{ t('general.delete') }}
  </button>
</div>
