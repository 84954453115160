import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DecodedJwt } from './token.interface';
import { CachingService } from './caching.service';
import { Router } from '@angular/router';
// import { UserRole } from '../authentication/user-roles';

const LOCAL_STORAGE_KEY = 'shipsolution_auth';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _token: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userData: DecodedJwt | null = null;

  $loginChange = new Subject<{ userdata: DecodedJwt | null }>();

  constructor(
    private readonly cachingService: CachingService,
    private readonly router: Router
  ) {
    this._token = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (this._token) {
      this.userData = jwtDecode(this._token);
    } else {
      this.userData = null;
    }
  }

  set token(token: string | null) {
    this._token = token;

    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEY, token);
      this.userData = jwtDecode(token);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      this.cachingService.invalidateAllCaches();
      this.userData = null;
      this.router.navigate(['/auth/login']);
    }
  }

  get token() {
    return this._token;
  }

  get role() {
    return this.userData?.role;
  }

  get userData() {
    return this._userData;
  }

  set userData(userData: DecodedJwt | null) {
    if (typeof userData?.role?.capabilities === "string") userData.role.capabilities = JSON.parse(userData.role.capabilities);

    this._userData = userData;
    console.log("USERDATA", userData);
    this.$loginChange.next({ userdata: this._userData });
  }

  get displayName() {
    return this.userData?.name || this.userData?.company;
  }

  get isMimic() {
    return Boolean(this.userData?.mimicId);
  }

  get isLoggedIn() {
    return this.token != null;
  }
}
