import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { JwtService } from 'src/app/services/jwt.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-dialog-support',
  templateUrl: './dialog-support.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ]
})
export class DialogSupportComponent implements OnInit {
  supportData = new FormGroup({
    email: new FormControl<string>('', [Validators.email]),
    body: new FormControl<string>('', [Validators.required]),
    subject: new FormControl<string>('', [Validators.required]),
    type: new FormControl<string>('', [Validators.required])
  });
  provideEmail: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogSupportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private backendService: BackendService,
    private readonly jwtService: JwtService,
    private notify: NotificationService,
    private transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    if (!this.jwtService.isLoggedIn) {
      this.supportData.get('email').setValidators([Validators.required, Validators.email]);
      this.provideEmail = true;
    }
  }

  onCreateClicked() {
    this.backendService.put<'support/createTicket'>('support/ticket', {
      body: {
        subject: this.supportData.get('subject').value,
        body: this.supportData.get('body').value,
        type: this.supportData.get('type').value as 'bug' | 'feature',
        email: this.supportData.get('email').value,
        userAgent: navigator.userAgent,
        currentUri: window.location.href,
      }
    }).subscribe((res) => {
      this.notify.success(this.transloco.translate('support.ticket_success', { key: res.supportTicketKey }));
      this.dialogRef.close();
    });
  }
}
