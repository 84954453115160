<ng-container *transloco="let t">
  <app-header
    class="mat-elevation-z6"
    [class.hidden]="navigating"
    [navbarOpen]="showDrawer && sidenav?.open"
    [isMobile]="isMobileView"
    (navbarToggled)="navBarToggle()"
  ></app-header>
  <br />
  <!-- Creates a layout with a left-positioned sidenav and explicit content. -->
  <mat-sidenav-container class="main-container" [class.content-page]="showDrawer">
    <mat-sidenav
      *ngIf="showDrawer"
      [mode]="isMobileView ? 'over' : 'side'"
      [opened]="!isMobileView || isOpened"
      (opened)="isOpened = true"
      (closed)="isOpened = false"
      #sideNav
    >
      <app-navigation
        [staticPageMenu]="staticPageMenu"
        (handleStaticPageAction)="handleStaticPageAction($event)"
        (itemSelected)="onMenuItemSelected()"
      ></app-navigation>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="navigating"
        style="position: absolute; top: 40%; left: 0"
      ></mat-progress-bar>
      <div [class.navigating]="navigating" [class.full-width]="isMobileView" class="main-layout" *ngIf="!awaitingLogin">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
      <!-- <mat-card *ngIf="!awaitingLogin" style="margin-left: auto; margin-right: auto; max-width: 400px">
        <mat-card-content>
          <mat-progress-spinner></mat-progress-spinner>
          <div><mat-icon>dns</mat-icon> {{ t('login.connecting') }}...</div>
        </mat-card-content>
      </mat-card> -->
    </mat-sidenav-content>
  </mat-sidenav-container>

  <div *ngIf="showReload" class="floating-btn">
    <a mat-raised-button color="accent" (click)="reload()">
      Update! (Auto update in {{ reloadTime }}s)
      <mat-icon>refresh</mat-icon>
    </a>
  </div>
</ng-container>
