import '@angular/common/locales/global/de';
import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter';
import { TranslocoRootModule } from './app/transloco-root.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { InterceptorService } from './app/http/interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DatePipe } from '@angular/common';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app/app.routes';

(window as any).global = window;

export const LUXON_FORMATS = {
  parse: {
    dateInput: 'dd.LL.yyyy',
    monthInput: 'LLLL',
    yearInput: 'yyyy',
    timeInput: 'HH:mm',
    datetimeInput: 'dd.LL.yyyy HH:mm'
  },
  display: {
    dateInput: 'dd.LL.yyyy',
    monthInput: 'LLLL',
    yearInput: 'yyyy',
    timeInput: 'HH:mm',
    datetimeInput: 'dd.LL.yyyy HH:mm',
    monthYearLabel: 'LLLL yyyy',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'LLLL yyyy',
    popupHeaderDateLabel: 'LLL dd, ccc'
  }
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(
      BrowserModule,
      MatSnackBarModule,
      MatNativeDateModule,
      TranslocoRootModule,
      MtxLuxonDatetimeModule,
      ServiceWorkerModule.register('push-sw.js', {
        enabled: true,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      })
    ),
    DatePipe,
    provideLuxonDateAdapter(LUXON_FORMATS),
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: LUXON_FORMATS
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      // ToDo: make it dynmaic on language/locale change
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useFactory: (translate: TranslocoService) => translate.getActiveLang()
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
}).catch((err) => console.error(err));
