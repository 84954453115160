import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ProcessResult } from './interfaces/process-result.interface';
import { IdToNamePipe } from 'src/app/pipes/id-to-name.pipe';
import { IdToVesselNamePipe } from 'src/app/pipes/id-to-vessel.pipe';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@ngneat/transloco';
import { INPUT_TYPES } from 'src/app/process-engine/process/process-handler/step-handle-manual-input/step-handle-manual-input.component';
import { SelectOnMapDialogComponent } from '../select-on-map/select-on-map-dialog.component';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { DeviceDialogSize } from 'src/app/process-engine/process/process-handler-dialog/interfaces/device-dialog-size.interface';
import { BackendService } from 'src/app/services/backend.service';
@Component({
  selector: 'app-process-result-dialog',
  templateUrl: './process-result-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    IdToVesselNamePipe,
    IdToNamePipe,
    AsyncPipe,
    MatCardModule,
    DatePipe,
    TranslocoModule
  ]
})
export class ProcessResultDialogComponent implements OnInit {
  processResult: ProcessResult;
  inputTypes = INPUT_TYPES;
  dimensions: DeviceDialogSize;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProcessResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { processId: number },
    private readonly backendService: BackendService,
    processHandlerService: ProcessHandlerService
  ) {
    this.dimensions = processHandlerService.getDialogScreenSize();
  }

  ngOnInit(): void {
    this.backendService.get<'processEngineProcess/getResult'>('process-engine/process/{id}/result', {
      path: { id: this.data.processId }
    }).subscribe((e) => {
      this.processResult = e;
    });
  }

  openMapDialog(input) {
    const dialogRef = this.dialog.open<SelectOnMapDialogComponent>(SelectOnMapDialogComponent, {
      ...this.dimensions,
      data: { position: JSON.parse(input), readonly: true }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.mapPosition) {
        input.value = JSON.stringify(data.mapPosition);
      }
    });
  }
}
