<mat-card *transloco="let t">
  <div class="flex align-center">
    <div class="hidden-xs flex column">
      <mat-card-title class="gap-xs">{{ t('login.welcome.title') }}</mat-card-title>
      <mat-card-content>
        <div style="max-width: 450px; height: 100%" class="flex column space-between">
          <p>{{ t('login.welcome.description') }}</p>
          <div>
            <img src="/assets/logo/company_logo.png" width="100%" />
            <hr />
            <div class="flex align-center">
              <img src="/assets/logo/logo_default_small.png" height="32px" />
              <small>
                <b>Version {{ version }}</b>
              </small>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div>
      <mat-card-title class="gap-xs visible-xs">
        <div class="flex align-center column">
          <img src="/assets/logo/company_logo.png" width="100%" />
          <span>{{ t('login.welcome.title') }}</span>
        </div>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <p class="flex align-center">
            <mat-form-field>
              <mat-icon matIconPrefix class="material-symbols-outlined space-xs-right">email</mat-icon>
              <input appAutofocus type="email" matInput placeholder="E-Mail" formControlName="email" />
              <mat-error *ngIf="form.get('email').errors?.required">{{ t('general.required_field') }}</mat-error>
              <mat-error *ngIf="form.get('email').errors?.email">{{ t('support.error_email') }}</mat-error>
            </mat-form-field>
          </p>
          <p class="flex align-center">
            <mat-form-field>
              <mat-icon matIconPrefix class="material-symbols-outlined space-xs-right">vpn_key</mat-icon>
              <input type="password" matInput [placeholder]="t('form.password')" formControlName="password" />
              <mat-error *ngIf="form.get('password').errors?.required">{{ t('general.required_field') }}</mat-error>
            </mat-form-field>
          </p>
          <mat-error *ngIf="error" style="max-width: 336px">
            {{ error }}
          </mat-error>
          <div class="flex align-center space-between">
            <div>
              <a routerLink="/auth/forgot-password">{{ t('login.form.forgot-password') }}</a>
            </div>
            <button
              type="submit"
              mat-raised-button
              color="accent"
              class="login-btn"
              [disabled]="subscriptionIsActive(loginSubscription) || form.invalid"
            >
              Login
              <mat-progress-bar
                *ngIf="subscriptionIsActive(loginSubscription)"
                [mode]="'indeterminate'"
              ></mat-progress-bar>
            </button>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions class="visible-xs">
        <div class="flex width-100 justify-center align-center">
          <img src="/assets/logo/logo_default_small.png" height="32px" />
          <small>
            <b>Version {{ version }}</b>
          </small>
        </div>
      </mat-card-actions>
    </div>
  </div>
</mat-card>
