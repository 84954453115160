import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../services/backend.service';

@Pipe({
  name: 'idToVessel',
  standalone: true
})
export class IdToVesselNamePipe implements PipeTransform {
  constructor(private readonly backendService: BackendService) {}

  transform(vesselId: any, ...args: unknown[]): Observable<string> {
    return new Observable<string>((observer) => {
      if (Number(vesselId)) {
        this.backendService.get<'asset/getAssetDetails'>('asset/{id}', {
          path: { id: vesselId }
        }).subscribe((asset) => {
            if (asset) {
              observer.next(`${asset.name}`);
            } else {
              observer.next('-');
            }
            observer.complete();
          }
        );
      }
      else {
        if (!vesselId) vesselId = '-';
        observer.next(vesselId);
      }
    });
  }
}
