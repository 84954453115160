export const Capability = {
  All: 'all',

  Asset: {
    create: 'asset.create',
    edit: 'asset.edit',
    view: 'asset.view',
    viewCategory: 'asset.view.category',
    editCategory: 'asset.edit.category',
    viewCertificates: 'asset.view.certificates',
    editCertificate: 'asset.edit.certificate',
    assign: 'asset.assign',
    reportDamage: 'asset.reportDamage',
    take: 'asset.take',
    confirm: 'asset.confirm'
  },

  Equipment: {
    edit: 'equipment.edit',
    view: 'equipment.view',
    viewClasses: 'equipment.view.classes',
    editClasses: 'equipment.edit.classes'
  },

  Emergency: {
    view: 'emergency.view',
    edit: 'emergency.edit',
    viewManual: 'emergency.view.manual',
    viewHistory: 'emergency.view.history'
  },

  User: {
    create: 'user.create',
    edit: 'user.edit',
    view: 'user.view',
    viewAll: 'user.view.all',
    viewGroups: 'user.view.groups',
    viewRoles: 'user.view.roles',
    viewWidgets: 'user.view.widgets',
    viewPushSubscriptions: 'user.view.pushSubscriptions',
    editPushSubscriptions: 'user.edit.pushSubscriptions',
    editRole: 'user.changeRole',
    editCapabilities: 'user.edit.capabilities',
    editGroup: 'user.edit.group',
    editWidgets: 'user.edit.widgets',
    editWidgetsForOtherRole: 'user.edit.widgetsForOtherRole'
  },

  View: {
    Dashboard: 'view.dashboard',
    Visits: 'view.visits',
    Me: 'view.me',
    Crew_Member: 'view.crew_member',
    Support_Epics: 'view.support.epics',
    Support_Tickets: 'view.support.tickets',
    Equipment_Manage: 'view.equipment.manage',
    Equipment_Overview: 'view.equipment.overview',
    Equipment_User: 'view.equipment.user',
    Equipment_Order: 'view.equipment.order',
    Equipment_Order_Quantity: 'view.equipment.order.quantity',
    Warehouse: 'view.warehouse',
    Vessel_Map: 'view.vessel.map',
    Own_Site: 'view.own.site',
    Vessel_TV: 'view.vessel.tv',
    Site: 'view.site',
    Inspector: 'view.inspector',
    Calendar: 'view.calendar',
    Assets: 'view.assets',
    Assets_Take: 'view.assets.take',
    Assets_List: 'view.assets.list',
    Assets_Map: 'view.assets.map',
    Assets_Details: 'view.assets.details',
    Documents: 'view.documents',
    UserGroups: 'view.usergroups',
    UserList: 'view.user.list',
    Settings: 'view.settings',
    SMV: 'view.smv'
  }
};

// TODO: Proper type
export type Capability = string;
