import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteModel } from 'src/app/models/site.model';
import { BackendService } from '../services/backend.service';

@Pipe({
  name: 'getSite',
  standalone: true
})
export class GetSitePipe implements PipeTransform {
  constructor(private backendService: BackendService) {}

  transform(value: number): Observable<SiteModel> {
    return new Observable<SiteModel>((observer) => {
      this.backendService.get<'site/getAll'>('site/all').subscribe((sites) => {
        observer.next(sites.find((s) => s.siteId === value) as any); // TODO: type
      });
    });
  }
}
