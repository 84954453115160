import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { deepCopy } from '../helper';

@Injectable({
  providedIn: 'root'
})
export class CachingService {
  private _cache: any = {};
  private _activeCacheSubscriptionObservers: { [s: string]: { observer: Observer<any>; staySubscribed?: boolean }[] } =
    {};
  private _activeCacheSubscriptions: { [s: string]: boolean } = {};

  /**
   * creates a new caching subscription for a given @subscriptionTarget
   *
   * @param cacheName - the name for the given cache
   * @param subscriptionTarget - the target of the Subscription (a Observable function, e.g a http call)
   * @param staySubscribed - whether to stay subscribed after a first observer.next() - call or not
   *
   * @returns an observable working as a proxy for the @subscriptionTarget
   */
  createCachingSubscription<T>(
    cacheName: string,
    subscriptionTarget: Observable<T>,
    staySubscribed?: boolean
  ): Observable<T> {
    return new Observable<T>((observer) => {
      const ctx = this;
      if (this._cache[cacheName]) {
        observer.next(deepCopy(this._cache[cacheName]));
        if (!staySubscribed) {
          observer.complete();
        } else {
          this.waitForSubscriptionTo(cacheName, staySubscribed).subscribe(observer);
        }
        return {
          unsubscribe(): void {
            ctx.unsubscribeSubscriptionFor(cacheName, observer);
          }
        };
      }
      // if one subscription of this type is already active
      if (this.subscriptionActive(cacheName)) {
        // wait for this subscription
        this.waitForSubscriptionTo(cacheName, staySubscribed).subscribe(observer);
        return {
          unsubscribe(): void {
            ctx.unsubscribeSubscriptionFor(cacheName, observer);
          }
        };
      }

      this.startSubscriptionOf(cacheName);
      const sub = subscriptionTarget.subscribe(
        (d) => {
          this.setCacheForSubscription(cacheName, d);
          observer.next(deepCopy(d));
          if (!staySubscribed) {
            observer.complete();
          } else {
            this.waitForSubscriptionTo(cacheName, staySubscribed).subscribe(observer);
          }
        },
        (e) => {
          observer.error(e);
          this.endSubscriptionOf(cacheName);
        }
      );
      return {
        unsubscribe(): void {
          ctx.unsubscribeSubscriptionFor(cacheName, observer);
          sub.unsubscribe();
        }
      };
    });
  }

  /**
   * invalidate a given cache
   *
   * @param cacheName - the name of the cache
   */
  public invalidateCache(cacheName: string): void {
    delete this._cache[cacheName];
    delete this._activeCacheSubscriptions[cacheName];
  }

  /**
   * invalidate all caches
   */
  public invalidateAllCaches(): void {
    this._cache = {};
  }

  /**
   * let an observer unsubscribe from a certain subscription
   *
   * @param subscription - the subscription the observer wants to be unsubscribed from
   * @param observer - the observer
   */
  public unsubscribeSubscriptionFor(subscription: string, observer: Observer<any>): void {
    this._activeCacheSubscriptionObservers[subscription] = this._activeCacheSubscriptionObservers[subscription].filter(
      (o) => o.observer !== observer
    );
  }

  /**
   * check if a activeCacheSubscription is active (= waiting for data)
   *
   * @param subscription - the subscription
   */
  private subscriptionActive(subscription: string): boolean {
    return !!this._activeCacheSubscriptions[subscription];
  }

  /**
   * sets the cache value for a subscription
   *
   * @param cacheName - the name of the cache
   * @param cacheData - the data that shall be stored in the cache
   */
  private setCacheForSubscription(cacheName: string, cacheData: any): void {
    this._cache[cacheName] = deepCopy(cacheData);
    // iterate through all subscriptions for this cache
    for (const observerData of this._activeCacheSubscriptionObservers[cacheName]) {
      observerData.observer.next(deepCopy(this._cache[cacheName]));
      if (!observerData.staySubscribed) {
        // if the obsever doesn't want to be subscribed
        observerData.observer.complete(); // complete its observation
      }
    }
    delete this._activeCacheSubscriptions[cacheName]; // since we have the cached data, we delete the subscriptions array of the cacheName
    // remove all observers that don't want to stay subscribed (since we all have completet their observation)
    this._activeCacheSubscriptionObservers[cacheName] = this._activeCacheSubscriptionObservers[cacheName].filter(
      (o) => o.staySubscribed
    );
  }

  /**
   * for subscribing the observer to the activeCacheSubscription instead of the actual subscription
   *
   * @param subscription - the subscription that the observer should be subscribed to
   * @param [staySubscribed] - whether the observer shall stay subscribed after the cacheSubscription completed
   */
  private waitForSubscriptionTo(subscription: string, staySubscribed?: boolean): Observable<any> {
    return new Observable((observer) => {
      this._activeCacheSubscriptionObservers[subscription] = this._activeCacheSubscriptionObservers[subscription] || [];
      this._activeCacheSubscriptionObservers[subscription].push({ observer, staySubscribed });
    });
  }

  /**
   * start a activeCacheSubscription for a given {subscription} (= mark as waiting for data)
   *
   * @param subscription - the subscription
   */
  private startSubscriptionOf(subscription: string): void {
    this._activeCacheSubscriptions[subscription] = true;
    // initialize the observer array
    this._activeCacheSubscriptionObservers[subscription] = this._activeCacheSubscriptionObservers[subscription] || [];
  }

  private endSubscriptionOf(subscription: string): void {
    delete this._activeCacheSubscriptions[subscription];
    this._activeCacheSubscriptionObservers[subscription] = [];
  }
}
